@use '../../../components/styles/variables.module' as *;

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  /*
   * Force WebKit to use subpixel antialiasing all the time.  This fixes a bug
   * where dragging/resizing a grid element, which uses translate3d(), causes
   * WebKit to fall back to antialias font smoothing instead of subpixel antialias
   * font smoothing.
   */
  -webkit-font-smoothing: subpixel-antialiased;
}

body {
  background-color: $background-color2;
}

.sb-terraced-grid {
  background-color: white;
}

body, .sb-email-styles {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.42857143;
}



h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin: 0;
}

h1, .h1 {
  font-size: 2.25em;
}

h2, .h2 {
  font-size: 1.75em;
}

h3, .h3 {
  font-size: 1.5em;
}

h4, .h4 {
  font-size: 1.2em;
}

ins {
  border-bottom:1px solid green;
  text-decoration: none;
  color:green;
}

del {
  color:red;
  text-decoration:line-through;
}

a,
a:hover {
  text-decoration: none;
}

button {
  transition: all .2s ease-in-out;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  cursor: pointer;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}
