@use "../../../components/styles/variables.module" as *;
@use "a-btn";

.sb-block .sb-school {
    .sb-school-title {
        margin-bottom: 0;
        color: $black;
    }
    .sb-school-grades, .sb-school-city {
        margin-bottom: 0;
        color: $black;
    }

    .sb-school-image-container {
        text-align: center;
        overflow: hidden;
        height: 100%;
        padding-bottom: 4.3em;
    }

    .sb-school-default-image {
        width: 75%;
        margin: 20% 0;
    }

    .sb-school-image-auto-width {
        max-height: 100%;
        max-width: 100%;
    }

    .sb-school-image-full-size {
        height: 100%;
        width: 100%;
        background: no-repeat center center;
    }

    .sb-blockMeta-content {
        text-align: center;

        p {
            white-space: normal;
        }
    }
}

.modal-title {
    text-align: center;
}


.school-modal {
    color: $gray;

    .sb-school-image-container {
        text-align: center;
        overflow: hidden;
        padding-bottom: 5%;
        border-radius: 20px 20px 0px 0px;
    }

    .sb-school-default-image {
        width: 75%;
        margin: 5% 0;
    }

    .sb-school-image-auto-width {
        max-height: 100%;
        max-width: 100%;
    }

    .head {
        padding: 0 5%;
        clear: both;
        h1, h3 {
            display: inline-block;
            width: 100%;
        }
        h4 {
            padding-top: 0em;
            margin-bottom: 1em;
        }
        table {
            table-layout: fixed;
        }
    }
    .contact {
        padding: 2% 5% 5% 5%;
        .left {
            width: 48%;
            min-height: 240px;
            display: inline-block;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                .location-entry {
                    [contentEditable=true]:empty:not(:focus)::before {
                        content:attr(data-placeholder)
                    }
                }

                li {
                    &.location {
                        .directions {
                            float: right;
                            border: 1px solid $border-color;
                            border-radius: 10px;
                            width: auto;
                            height: fit-content;
                            margin-left: 10px;
                            color: $gray;
                            padding: 1em;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            img {
                                margin-top: 0.5em;
                            }
                            span {
                                display: block;
                                margin-top: 7px;
                                margin-left: 0;
                                font-size: 1em;
                            }
                        }
                        .address {
                            overflow: hidden;
                            padding-left: 6px;
                            p {
                                margin: 0;
                                padding: 0;
                            }
                            span {
                                margin-left: 0px;
                            }
                        }
                    }
                    > div {
                        vertical-align: middle;
                        width: 100%;
                    }
                    &.phone {
                        margin: 15px 0px 50px 10px;
                        span[class*="fa-"] {
                            vertical-align: middle;
                        }
                    }
                    &.editor {
                        height: auto;
                        min-height: 80px;
                    }
                }
            }
        }
        .right {
            width: 48%;
            float: right;
            position: relative;
        }
    }
}
