$gFilePickerWidth: 602px;
$gFilePickerHeight: 502px;

iframe#google-file-picker-receiver {
  border: none;
  width: $gFilePickerWidth; // this is the static size of the picker
  height: $gFilePickerHeight; // this is the static size of the picker
}

.google-file-picker-modal-width {
  width: $gFilePickerWidth;
}

.google-file-picker {
  margin-left: 0;

  svg {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }

  span {
    margin-left: 5px;
    font-weight: bold;
    vertical-align: middle;
  }
}
