@font-face {
  font-family: 'fontIconPicker';
  src:
    url('fonts/fontIconPicker.ttf?an4nl5') format('truetype'),
    url('fonts/fontIconPicker.woff?an4nl5') format('woff'),
    url('fonts/fontIconPicker.svg?an4nl5#fontIconPicker') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="fipicon-"], [class*=" fipicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fontIconPicker' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fipicon-angle-left:before {
  content: "\f104";
}
.fipicon-angle-right:before {
  content: "\f105";
}
.fipicon-angle-up:before {
  content: "\f106";
}
.fipicon-angle-down:before {
  content: "\f107";
}
