@use "../../../components/styles/variables.module" as *;

.sb-page-width {
    width: 700px;
}

.sb-page-container {
    position: relative;
    height: auto;
    border: 0px;
    background: #FFF;
    border-radius: $block-border-radius;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
}

.sb-page-header {
    display: table;
    content: " ";
    clear: both;
    border-bottom: 1px solid #e7e7e7;
    padding: 1em;
    width: 100%;

    h2 {
        line-height: 1.75em;
    }

    .sb-ada-page-errors-count {
        margin-right: 2em;
        margin-top: 0.5em;
        line-height: 1.25em;

        span {
            &:first-child {
                display: block;
            }

            &:last-child {
                a:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .btn {
        margin: 0.285em 0;
        font-size: 1em;
        line-height: 1.75em;
    }
}

.sb-ada-page-errors-table {
    margin: 1em;
    display: none;

    .panel-heading > span {
        margin-top: 0.5em;
    }
}

#pageBody.sb-page-body {
    padding: 1em;
}

#comparePageModal {
    #compareResult {
        padding-right: 15px;
    }
    .modal-body > button[disabled] {
        background-color: #337ab7;
    }
}

#test-results-table {
    tbody > tr > td:last-child {
        width: 85px;
    }

    ul {
        padding-left: 0.5em;
        list-style-type: none;
    }
}
