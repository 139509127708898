// Search
.rfipsearch {
	width: 100%;
	margin: 0 0 8px;
	input {
		width: 100%;
		display: block;
		height: 32px;
		line-height: 32px;
	}
}
