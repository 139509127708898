@use "sass:math";
@use '../../../components/styles/variables.module' as *;

@mixin block-widths($width, $max-columns) {
  $page-max-columns: 4;

  /* width = columnWidth * gridX - marginX */
  .sb-column-width { width: $width; position: fixed; top: 0; left: 0; }

  $z: 1;
  @for $i from 1 through $page-max-columns {
    .sb-w#{$i} { width: ($width * $z) - $block-margin-horizontal; }

    @if ($z < $max-columns) {
      $z: $z + 1;
    }
  }
}

@mixin block-heights($height) {
  /* height = rowHeight * gridY - marginY */
  $max-rows: 10;

  @if ($height == $row-height-small) {
    .sb-min-height {
      min-height: ($height * 2) - $block-margin-vertical;
      max-height: ($height * 8) - $block-margin-vertical;
    }
  } @else {
    .sb-row-height { height: $height; position: fixed; top: 0; left: 0; }
    .sb-row-height-with-header { height: $height + 26px; position: fixed; top: 0; left: 0; };
    .sb-row-height-teacher-with-header { height: $height + 52px; position: fixed; top: 0; left: 0; };

    .sb-min-height {
      max-height: initial;
    }
    .sb-min-height.sb-h1 { height: ($height) - $block-margin-vertical; }

    @for $i from 1 through $max-rows {
      .sb-h#{$i} { height: ($height * $i) - $block-margin-vertical; }
    }
  }

  // The map still uses this block height...we need it.
  @for $i from 1 through $max-rows {
    #sb-organizations-map.sb-h#{$i} { height: ($height * $i) - $block-margin-vertical; }
  }
}

@mixin block-heights-aspect-ratio($height) {
  $max-rows: 10;

  /*
    This part of the function tries to respect the fact that certain blocks try to be rectangular on a desktop view
    but don't look quite right when viewed on a device with less columns (i.e. mobile, with 1 column).  Here's how this
    works:

    Step 1: Only do something if block width is >= $rectangle-max-columns.  (On mobile, this is all block widths)
    Step 2: Determine the aspect ratio with $w / $h.
    Step 3: Compare the aspect ratio to 0.5 (which is a square block for us).  If the value is > 0.5, then the block
            is wider than tall.  If the value is < 0.5, then the block is taller than wide.
    Step 4: For blocks that are wider than tall, set the height = $height * $rectangle-max-columns (minus margin)

    Examples:
       square: w1,h2; w2, h4 => ratio: w1/h2 = 0.5
       rectangle (taller than wide): w1,h3, w1,h4, w2,h5 => ratio: w1/h3 = 0.3333
       rectangle (wider than tall): w1,h1, w2,h2, w4,h2 => ratio: w2/h2 = 1
    */
  @for $w from 1 through 4 {
    @for $h from 1 through $max-rows {
      @if (math.div($w, $h) > 0.5) {
        &.sb-w#{$w}.sb-h#{$h} { height: $height - $block-margin-vertical }
      }
    }
  }
}

@mixin screen-xs {
  @media (min-width: $break-point-xs) and (max-width: ($break-point-sm - 1)) {
    @content;
  }
}

@mixin max-screen-xs {
  @media (max-width: $break-point-sm - 1) {
    @content;
  }
}

@mixin min-screen-sm {
  @media (min-width: $break-point-sm) {
    @content;
  }
}

@mixin max-screen-sm {
  @media (max-width: $break-point-md - 1) {
    @content;
  }
}

@mixin max-screen-md {
  @media (max-width: $break-point-lg - 1) {
    @content;
  }
}

@mixin min-screen-lg {
  @media (min-width: $break-point-lg) {
    @content;
  }
}
