// this file is asynchronously loaded

@use "variables.module";
@mixin font-face($font-path, $style-name, $file, $family, $weight:"normal", $style:"normal", $category:"") {
  $filepath: $font-path + $file;
  @font-face {
    @if $category != "" {
      font-family: "#{$style-name}", "#{$category}";
    } @else {
      font-family: "#{$style-name}";
    }
    font-weight: #{$weight};
    font-style: #{$style};
    src: url($filepath + ".eot");
    src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
         url($filepath + ".woff") format('woff'),
         url($filepath + ".ttf") format('truetype'),
         url($filepath + ".svg#" + $style-name + "") format('svg');
  }
}


html {
  font-size: 12px;
}

// This has been moved to the SchoolBlocksClientScript::registerCoreCssAsync() function
//@import url('//fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,400,600,700');

@include font-face('../fonts/', 'SPO365Icons', 'Office365Icons', 'SPO365Icons');

.sb-office365-icons {
    display: inline-block;
    font: normal normal normal 14px/1 SPO365Icons;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.sb-office365-logo:before {
    content: "\e160";
}

.bold {font-weight: bold}

$ms-font-cdn-path: 'https://static2.sharepointonline.com/files/fabric/assets/fonts';
$cdn-folder: 'segoeui-arabic';
$cdn-font-name: 'segoeui';
$ms-font-weight-semibold: 600;

@font-face {
    font-family: 'Segoe UI';
    src: url('#{$ms-font-cdn-path}/#{$cdn-folder}/#{$cdn-font-name}-semibold.woff2') format('woff2'),
    url('#{$ms-font-cdn-path}/#{$cdn-folder}/#{$cdn-font-name}-semibold.woff') format('woff');
    font-weight: $ms-font-weight-semibold;
    font-style: normal;
}
