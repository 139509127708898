$z-index-cleverfeed-sidebar: 10;

$z-index-terrace-block-title: 100;
$z-index-map-terrace-title: 800;

$z-index-navigation-secondary: 801;
$z-index-header-shortcuts: 802;
$z-index-navigation-primary: 802;
$z-index-header-shortcuts: 802;
$z-index-admin-buttons: 803;

$z-index-schoolfeed-header: 901;
$z-index-cleverfeed-header: 902;

$z-index-translation-tool: 1001;
$z-index-mobile-nav-popup: 1004;
$z-index-mobile-nav: 1005;
$z-index-modal-backdrop: 1040;
$z-index-modal: 1040;
$z-index-flux-capacitor: 1049;

:export {
  z-index-modal: $z-index-modal;
  z-index-cleverfeed-sidebar: $z-index-cleverfeed-sidebar;
}
