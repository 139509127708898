/*!
 * Fancytree "Win7" skin.
 *
 * DON'T EDIT THE CSS FILE DIRECTLY, since it is automatically generated from
 * the LESS templates.
 */
/*******************************************************************************
 * Common Styles for Fancytree Skins.
 *
 * This section is automatically generated from the `skin-common.less` template.
 ******************************************************************************/


/*
 * Overrides from bootstrap theme
 */
table.fancytree-ext-table tbody tr td {
  border: none;
}
table.fancytree-ext-table.fancytree-treefocus tbody tr.fancytree-focused span.fancytree-title {
  outline: none;
}
table.fancytree-ext-table tbody tr.fancytree-active,
table.fancytree-ext-table tbody tr.fancytree-active span.fancytree-node,
table.fancytree-ext-table.fancytree-treefocus tbody tr.fancytree-active,
table.fancytree-ext-table.fancytree-treefocus tbody tr.fancytree-active span.fancytree-node {
  background-color: #ccc;
}
table.fancytree-ext-table.fancytree-treefocus tbody tr:hover,
table.fancytree-ext-table tbody tr:hover {
  background-color: #eee;
}

/*------------------------------------------------------------------------------
 * Common icon definitions
 *----------------------------------------------------------------------------*/
span.fancytree-drag-helper-img,
#fancytree-drop-marker {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("../images/quick-links/icons.gif");
  background-position: 0px 0px;
}
/*------------------------------------------------------------------------------
 * Drag'n'drop support
 *----------------------------------------------------------------------------*/
div.fancytree-drag-helper a {
  border: 1px solid gray;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  opacity: 0.8;
}
div.fancytree-drag-helper.fancytree-drop-reject {
  border-color: red;
}
div.fancytree-drop-accept span.fancytree-drag-helper-img {
  background-position: -32px -112px;
}
div.fancytree-drop-reject span.fancytree-drag-helper-img {
  background-position: -16px -112px;
}
/*** Drop marker icon *********************************************************/
#fancytree-drop-marker {
  width: 32px;
  position: absolute;
  background-position: 0px -128px;
  margin: 0;
}
#fancytree-drop-marker.fancytree-drop-after,
#fancytree-drop-marker.fancytree-drop-before {
  width: 64px;
  background-position: 0px -144px;
}
#fancytree-drop-marker.fancytree-drop-copy {
  background-position: -64px -128px;
}
#fancytree-drop-marker.fancytree-drop-move {
  background-position: -32px -128px;
}
/*** Source node while dragging ***********************************************/
span.fancytree-drag-source {
  background-color: #e0e0e0;
}
span.fancytree-drag-source span.fancytree.title {
  color: gray;
}
/*** Target node while dragging cursor is over it *****************************/
span.fancytree-drop-target.fancytree-drop-accept a {
  background-color: #3169C6 !important;
  color: white !important;
  /* @ IE6 */
  text-decoration: none;
}
