@use '../../../components/styles/variables.module' as *;

.sb-theme-minimal {
  .sb-navbar-table {
    .sb-navbar-row {
      .sb-navbar-cell {
        width: 100%;
        height: 5em;
        line-height: 5em;
        position: relative;
        text-align: left;
      }
    }
  }

  .sb-navbar-primary-element.sb-navbar-primary-element-logo {
    float: left;
  }

  .sb-organization-color-secondary-nav {
    border-top: none;

    color: var(--primaryNavFontColor);
    a, button {
      color: var(--primaryNavFontColor);

      font-family: proxima-nova, Arial, sans-serif !important;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: normal;
      font-style: normal;
      line-height: 70px !important;
    }

    .sb-navbar-link-text:hover {
      color: var(--elements);

    }

    .sb-navbar-link-text:hover,
    .sb-navbar-link-text:focus,
    .sb-navbar-link-text:active,
    .sb-navbar-link-text.active {
      border-bottom: 2px solid var(--primaryNavFontColor);
    }

    @media (max-width: $break-point-lg) {
      display: none;
    }
  }

  .sb-minimal-theme-login-button-container {
    position: absolute;
    top: 0;
    right: 0;
    white-space: nowrap;
    z-index: 11;

    @media(max-width: $break-point-lg) {
      margin-top: 30px;
    }

    #loginMenuButton, #userMenuButton {
      line-height: 1.42857143;
      padding: 0;
      @media(max-width: $break-point-lg) {
        display: none;
      }
    }
  }

  @media only screen and (min-width: $break-point-md) {
    #sb-content-canvas {
      top: 6em;
    }
  }
}
