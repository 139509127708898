.sb-footer {
    background-color: #3e3f42;
    // ADA compliance color
    color: #e0e0e2;
    margin-top: 1em;
    padding: 1em 0 3em 0;
    position:relative;

    ul {
        list-style: none;
        padding: 0;

        li {
            margin-top: 1em;

            [class*="fa-"] {
                margin-right: 0.5em;
            }

            &.sb-address-icon:before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: '\f3c5'; /* fas fa-map-marker-alt */
            }

            &.sb-phone-icon:before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: '\f095'; /* fas fa-phone */
            }
        }
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            color: #fff;
        }

    }

    .sb-footer-title {
        color: #fff;
        text-transform: uppercase;
    }
}
