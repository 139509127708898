@use "sass:meta";
@use "../../../../../node_modules/contentbuilderjs/assets/icons/css/fontello.css";
@use "../../../../../node_modules/contentbuilderjs/scripts/icons/css/fontello.css" as contentbuilder;
/*
This is the *content* associated with content builder.  Will be used in page blocks to view the page appropriately.
 */
.scoped-content-builder {
  // important to not use ".css" extension so that sass knows it's not just a regular css import
  // sass doesn't understand how to import modules imported in imports, it assumes that the url() paths are relative to the origin (this file),
  // therefore, all imports within these files are aliased in the webpack config so that webpack can resolve them properly
  @include meta.load-css("~contentbuilderjs/assets/default/content");
}
