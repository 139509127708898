@use "variables.module";

.sb-card-sponsor {
  .sb-sponsor-image {
    height: 16rem;
    max-width: 16rem;
    background-color: #fff;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 5px;
    border-style: none;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    margin:0 auto;
    display: block;
    position: relative;

    &[data-canEdit="true"] {
      cursor: pointer;

      &:hover:after {
        content: "\f030";
        background-color: rgba(0,0,0,0.4);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 5px;
        padding-top: 6rem;
        bottom: 0;
        font-family: 'Font Awesome 5 Free';
        color: white;
        font-size: 2.5rem;
      }
    }
  }
}

/* Category type colors */
.sb-background-color-1.sb-filter-button,
.sb-background-color-2.sb-filter-button,
.sb-background-color-3.sb-filter-button,
.sb-background-color-4.sb-filter-button,
.sb-background-color-5.sb-filter-button,
.sb-background-color-6.sb-filter-button {
  background-color: #CCC;
  outline: 0 none;
  color: #000; }
.sb-background-color-1,
.sb-background-color-1.sb-filter-button:hover,
.sb-background-color-1.sb-filter-button:active,
.sb-background-color-1.sb-filter-button.active {
  background-color: #eb6841;
  color: #FFF; }
.sb-background-color-2,
.sb-background-color-2.sb-filter-button:hover,
.sb-background-color-2.sb-filter-button:active,
.sb-background-color-2.sb-filter-button.active {
  background-color: #edc951;
  color: #000; }
.sb-background-color-3,
.sb-background-color-3.sb-filter-button:hover,
.sb-background-color-3.sb-filter-button:active,
.sb-background-color-3.sb-filter-button.active {
  background-color: #cc2a36;
  color: #FFF; }
.sb-background-color-4,
.sb-background-color-4.sb-filter-button:hover,
.sb-background-color-4.sb-filter-button:active,
.sb-background-color-4.sb-filter-button.active {
  background-color: #4f372d;
  color: #FFF; }
.sb-background-color-5,
.sb-background-color-5.sb-filter-button:hover,
.sb-background-color-5.sb-filter-button:active,
.sb-background-color-5.sb-filter-button.active {
  background-color: #00a0b0;
  color: #FFF; }
.sb-background-color-6,
.sb-background-color-6.sb-filter-button:hover,
.sb-background-color-6.sb-filter-button:active,
.sb-background-color-6.sb-filter-button.active {
  background-color: #F96F2F;
  color: #FFF; }


