@use "../../../components/styles/variables.module" as *;
@use './mixins';

.sb-collection-filter-bar {
    position: relative;
    margin: 0 auto;

    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;

    text-align: left;
    color: #333;

    padding: 0;

    font-size: 1.5rem;
    width: 100%;
    @include mixins.min-screen-sm {
        font-size: 2rem;
        width: ($column-width * 2) + 50px;
    }

    &-row {
        vertical-align: middle;
        display: flex;
        align-items: center;
    }

    &-search {
        font-weight: bold;
        padding: 0 1rem;
        flex: 1 1 auto;
        order: 0
    }

    &-line {
        border-left: 1px black solid;
        height: 2rem;
        flex: 1 1 auto;
        order: 2;
    }

    &-map {
        font-weight: bold;
        padding: 0 1.5rem;
        flex: 1 1 auto;
        order: 3;

        color: $black;
        &:active,&.active {
            color: #e8702a;
        }

        button {
            border: none;
            margin: 0;
            padding: 0;
            width: auto;
            overflow: visible;

            background: transparent;

            /* inherit font & color from ancestor */
            color: inherit;
            font: inherit;

            /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
            line-height: normal;

            /* Corrects font smoothing for webkit */
            -webkit-font-smoothing: inherit;
            -moz-osx-font-smoothing: inherit;

            /* Corrects inability to style clickable `input` types in iOS */
            -webkit-appearance: none;

            text-align: inherit;
        }
    }

    &-input {
        width: 100%;
        flex: 5 5 auto;
        order: 1;

        tags {
            width: 100%;
        }

        .tagify {
            border: none !important;
        }

        input, textarea {
            width: 100%;
            border: none;

            color: inherit;
            font-size: inherit !important;
            font-weight: normal;
        }
    }

    &-tag {
        display: inline-block;
        border: none;
        background: transparent;
        color: inherit;
        margin: 0;
        padding: 0;

        font-weight: normal;
        text-transform: lowercase;

        &:focus {
            outline: none;
        }

        &.active {
            border-bottom: 1px solid;
        }
    }
}

.sb-no-filter-results-msg {
    display:none;
    text-align: center;
    margin-top: 7px;
    min-height: 470px;

    &.active {
        display: block;
    }
}
