@font-face {
  font-family: 'sbicon';
  src: url('./sbicon.eot?54330009');
  src: url('./sbicon.eot?54330009#iefix') format('embedded-opentype'),
       url('./sbicon.woff2?54330009') format('woff2'),
       url('./sbicon.woff?54330009') format('woff'),
       url('./sbicon.ttf?54330009') format('truetype'),
       url('./sbicon.svg?54330009#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?54330009#fontello') format('svg');
  }
}
*/
[class^="sbicon-"]:before, [class*=" sbicon-"]:before {
  font-family: "sbicon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.sbicon-google-classroom:before { content: '\e800'; } /* '' */
.sbicon-alma:before { content: '\e801'; } /* '' */
.sbicon-calendar:before { content: '\e802'; } /* '' */
.sbicon-colorado-transparency:before { content: '\e803'; } /* '' */
.sbicon-classtag:before { content: '\e804'; } /* '' */
.sbicon-clever:before { content: '\e805'; } /* '' */
.sbicon-infinite-campus-2:before { content: '\e806'; } /* '' */
.sbicon-parentsqaure:before { content: '\e807'; } /* '' */
.sbicon-infinite-campus:before { content: '\e808'; } /* '' */
.sbicon-paymentportal2:before { content: '\e809'; } /* '' */
.sbicon-powerschool:before { content: '\e80a'; } /* '' */
.sbicon-registration:before { content: '\e80b'; } /* '' */
.sbicon-schoology:before { content: '\e80c'; } /* '' */
.sbicon-skyward:before { content: '\e80d'; } /* '' */
.sbicon-staff-listing:before { content: '\e80e'; } /* '' */
.sbicon-banner-alert:before { content: '\e80f'; } /* '' */
.sbicon-notifications:before { content: '\e810'; } /* '' */
.sbicon-onedrive:before { content: '\e811'; } /* '' */
.sbicon-q-aequitas:before { content: '\e812'; } /* '' */
.sbicon-classwize:before { content: '\e813'; } /* '' */
.sbicon-teams:before { content: '\e814'; } /* '' */
.sbicon-calendar-1:before { content: '\e815'; } /* '' */
.sbicon-outlook:before { content: '\e816'; } /* '' */
.sbicon-overlay-alert:before { content: '\e817'; } /* '' */
.sbicon-twitter-x:before { content: '\e818'; } /* '' */
.sbicon-jostens:before { content: '\e819'; } /* '' */
.sbicon-mischool-data:before { content: '\e81a'; } /* '' */
.sbicon-school-dude:before { content: '\e81b'; } /* '' */
.sbicon-211:before { content: '\e81c'; } /* '' */
.sbicon-myschoolbucks:before { content: '\e81d'; } /* '' */
.sbicon-classlink:before { content: '\e81e'; } /* '' */
.sbicon-chalkable:before { content: '\e81f'; } /* '' */
.sbicon-actionaly:before { content: '\e820'; } /* '' */
.sbicon-remind:before { content: '\e822'; } /* '' */
.sbicon-studentparentvue:before { content: '\e823'; } /* '' */
.sbicon-canvas:before { content: '\e824'; } /* '' */
.sbicon-google:before { content: '\e825'; } /* '' */
.sbicon-accountability-reports:before { content: '\e826'; } /* '' */
.sbicon-paypam:before { content: '\e827'; } /* '' */
.sbicon-ascender:before { content: '\e828'; } /* '' */
.sbicon-boarddocs:before { content: '\e829'; } /* '' */
.sbicon-users:before { content: '\e82a'; } /* '' */
.sbicon-follett:before { content: '\e82b'; } /* '' */
.sbicon-focus-school-software:before { content: '\e82c'; } /* '' */
.sbicon-frontline-education:before { content: '\e82d'; } /* '' */
.sbicon-hudl:before { content: '\e82e'; } /* '' */
.sbicon-people-6:before { content: '\e82f'; } /* '' */
.sbicon-my-paymentplus:before { content: '\e830'; } /* '' */
.sbicon-nutrislice:before { content: '\e831'; } /* '' */
.sbicon-paper:before { content: '\e832'; } /* '' */
.sbicon-peachjar:before { content: '\e833'; } /* '' */
.sbicon-people-2:before { content: '\e834'; } /* '' */
.sbicon-people-3:before { content: '\e835'; } /* '' */
.sbicon-people-4:before { content: '\e836'; } /* '' */
.sbicon-people-5:before { content: '\e837'; } /* '' */
.sbicon-cloud-sun:before { content: '\e838'; } /* '' */
.sbicon-people-7:before { content: '\e839'; } /* '' */
.sbicon-people-8:before { content: '\e83a'; } /* '' */
.sbicon-people-10:before { content: '\e83b'; } /* '' */
.sbicon-people-9:before { content: '\e83c'; } /* '' */
.sbicon-people-11:before { content: '\e83d'; } /* '' */
.sbicon-chart-bar:before { content: '\e83e'; } /* '' */
.sbicon-people-12:before { content: '\e83f'; } /* '' */
.sbicon-point-and-pay:before { content: '\e840'; } /* '' */
.sbicon-revtrack:before { content: '\e841'; } /* '' */
.sbicon-megaphone:before { content: '\e842'; } /* '' */
.sbicon-sapphire:before { content: '\e843'; } /* '' */
.sbicon-schoolcare:before { content: '\e844'; } /* '' */
.sbicon-silverware:before { content: '\e845'; } /* '' */
.sbicon-su-software:before { content: '\e846'; } /* '' */
.sbicon-aeries:before { content: '\e847'; } /* '' */
.sbicon-schoolfeed-1:before { content: '\e855'; } /* '' */
.sbicon-schoolfeed-2:before { content: '\e856'; } /* '' */
.sbicon-list-bullet:before { content: '\f0ca'; } /* '' */
.sbicon-user-md:before { content: '\f0f0'; } /* '' */
.sbicon-stethoscope:before { content: '\f0f1'; } /* '' */
.sbicon-medkit:before { content: '\f0fa'; } /* '' */
.sbicon-graduation-cap:before { content: '\f19d'; } /* '' */
.sbicon-paypal:before { content: '\f1ed'; } /* '' */
.sbicon-chart-area:before { content: '\f1fe'; } /* '' */
.sbicon-chart-pie:before { content: '\f200'; } /* '' */
.sbicon-chart-line:before { content: '\f201'; } /* '' */
.sbicon-address-book:before { content: '\f2b9'; } /* '' */
.sbicon-address-card:before { content: '\f2bb'; } /* '' */
.sbicon-id-badge:before { content: '\f2c1'; } /* '' */
.sbicon-id-card:before { content: '\f2c2'; } /* '' */
.sbicon-id-card-o:before { content: '\f2c3'; } /* '' */
