.ajax-poll-form {
	margin:0 auto;
	padding:0;
	text-align:left;
	width: 100%;
}
    .ajax-poll-form .panel-heading {
        padding: 5px 10px 10px 10px;
    }

    .ajax-poll-form .panel-body {
        padding: 2px;
    }
    
    .ajax-poll-form .list-group {
        margin-bottom: 15px;
        color: #666666;
    }
    
    .ajax-poll-form .list-group label {
        /*
        font-weight: bold;
        */
        font-size: 1.2em;
        padding-left: 2.5em;
    }

	.ajax-poll-title {
        color: #7F042C;
        font-weight: bold;
        font-size: 1.4em;
	}

	.ajax-poll-item {
		margin:0;
		padding:8px;
		cursor:pointer;
        border: none;
	}
    
        .ajax-poll-item .radio {
            margin: 0;
        }
        
            .ajax-poll-item .radio input[type=radio] {
                display: none;
            }
            
            .ajax-poll-item .radio label:before {  
               content: "";  
               display: inline-block;  

               width: 1.2em;
               height: 1.2em;

               margin-right: 1em;
               position: absolute;  
               left: 0;  
               top: 2px;
               background-color: #EEE;  
               box-shadow: inset 0 0 1px 0 rgba(255, 255, 255, 1.0), 0 1px 0 0 rgba(255, 255, 255, .8);
               border-radius: 50%;
               border: 1px solid #AAA;
           }

            .ajax-poll-item-sel .radio label:before {  
                content: "";
                display: inline-block;

                color: #696868;
                width: 1.2em;
                height: 1.2em;
                margin-right: 1em;
                left: 0;
                top: 2px;
                border: none;

                background-color: #696868;
                /* font-family: Arial, Helvetica, sans-serif; */
           }

	.ajax-poll-item-mover {
		/* hover style */
	}

	.ajax-poll-item-sel {
		border-radius: 15px;
		-moz-border-radius: 15px;
	}

		.ajax-poll-item-caption {
			font-size:18px;
			margin:0;
		}

		.ajax-poll-item-radio {
			margin:0 5px 0 0;
			padding:0;
		}

		.ajax-poll-item-stats-box {
			display:none;
			height:30px;
		}

			.ajax-poll-item-bar {
				margin:0;
				padding:0;
				width:0;
				height:5px;
				line-height:0;
				font-size:1px;
				background-color:#008000;
				border-radius: 3px;
				-moz-border-radius: 4px;
			}

			.ajax-poll-item-count {
				font-size:0.8em;
				margin:0 5px 0 0;
			}

			.ajax-poll-item-perc {
				font-size:0.8em;
				margin:0px;
			}

		.ajax-poll-item-desc-box {
			height:30px;
			font-size:0.8em;
			font-style:italic;
		}

.ajax-poll-vote-box {
	text-align:center;
	height:20px;
    padding: 0px 10px;
}

	.ajax-poll-btn-view,
	.ajax-poll-btn-vote,
	.ajax-poll-btn-back {
		float:left;
		margin: 0 !important;
		text-transform: uppercase;
		font-weight: bold;
		/*
		height: 40px;
		width:100px;
		*/
		padding: 0.5em;
		font-size: 1.3em;
	}

    .ajax-poll-btn-vote {
    	float: right;
    }


.ajax-poll-back-box {
	display:none;
	text-align:center;
	margin-top:20px;
	height:20px;
}

	.ajax-poll-total-box {
		float:right;
		font-size:15px;
		padding:5px 0 0 0;
	}

		.ajax-poll-total-caption {
			color:#808080;
		}

		.ajax-poll-total-value {
			color:#808080;
		}

.ajax-poll-clear-both {
	clear:both;
}

.ajax-poll-btn-reset {
	margin:10px 0;
	width:200px;
}

