// these are shims for other, asynchronously loaded styles
// maybe we can delete this. test it out.

.modal {
    display: none;
}

.hidden {
    display: none;
}
