@use "../../../components/styles/variables.module" as *;
@use 'third-party-colors';

.sb-login-container {
    margin: 0 auto;
}

.sb-login-block {
    display: block;
    position: relative;
    border: 0;
    background: #FFF;
    border-radius: $block-border-radius;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    margin: 0 7px;
    overflow: hidden;
    text-align: center;

    .sb-login-back-button {
        margin-top: 1em;
        margin-left: 30vw;
        padding: 0.4em;
        border: solid grey 2px;
        border-radius: 6px;
    }

    .sb-login-school-header {
        text-align: center;
        margin: 1em 0;
    }

    .sb-login-options {
        position: relative;
        height: 250px;
        padding: 1em 3em;
    }

    @media(max-width: $break-point-sm) {
        .sb-login-back-button {
            margin-left: 60vw;
        }
    }
}

.sb-login-button {
    display: inline-block;
    text-align: left;
    width: 265px;
    height: 44px;
    border-radius: 3px;
    padding: 2px;
    margin: 0.3em auto;
    font-size: 1.2em;
    color: #FFF;


    a, a:hover {
        color: inherit;
        text-decoration: none;
    }

    &-text {
        margin-left: 41px;
        line-height: 40px;
        text-align: center;

        &-google {
            font-family: 'Roboto', sans-serif;
        }

        &-office365 {
            font-family: 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
        }

        &-facebook {
            font-family: sans-serif;
        }

        &-skip {
            margin-left: 0;
            color: $gray;
            font-weight: 600;
        }
    }

    &-logo {
        height: 40px;
        width: 40px;
        background-color: #fff;
        display: inline-block;
        border-radius: 3px;
        vertical-align: middle;
        text-align: center;
        position: absolute;

        &-background {
            background: #FFF no-repeat center;
            background-size: contain;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.sb-background-color-skip-login {
    background-color: #FFF;
    border: 1px solid #CECECE;
}
