@use "../../../components/styles/variables.module" as *;

.sb-error-container {
    margin: 0 auto;
}

.sb-error-block {
    display: block;
    position: relative;
    border: 0;
    background: #FFF;
    border-radius: $block-border-radius;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    margin: 0 7px;
    overflow: hidden;
    text-align: center;

    .sb-error-school-header {
        text-align: center;
        margin: 1em 0;
    }

    .sb-error-message {
        position: relative;
        padding: 1em 3em;
    }
}

.sb-error-inside-block {
    text-align: center;
    color: #a94442;
    &-icon {
        font-size: 3em;
    }
    &-header {
        h2 {
            color: #a94442 !important;
        }
    }
    &-message {
        position: relative;
        text-align: left;
        margin-top: 2em;
    }
}
