// Reset style inside fip dropdown
.rfipbtn,
.rfipdropdown {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	font-size: 14px;
	line-height: (24/14);
	vertical-align: baseline;

	box-sizing: border-box;
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}
	input,
	select {
		font-size: 14px;
	}
}
