@use "../../../components/styles/variables.module" as *;

.sb-settings-block-bootstrap {
    display: block;
    position: relative;
    border: 0;
    background: #FFF;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    margin: 0 7px;

    .sb-settings-block-navigation {
        padding-top: 1em;
        padding-bottom: 1em;
        display: inline-block;
        float: none;
        vertical-align: top;
    }

    .sb-settings-block-content {
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        display: inline-block;
        float: none;
    }
}

.sb-settings-container {
    margin: 0 auto;
    padding: 0;
    .sb-settings-block-content-heading {
        margin: 2em 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 1em;
        strong {
            font-family: 'Open Sans', sans-serif;
        }
    }

    #notification-settings-form {
        margin: 20px 0;
    }

    .row {
        margin-bottom: 24px;
    }

    input,
    textarea {
        border-radius: 5px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.07);
        background: $background-color2;
        border: 1px solid #ccc;
        margin: 0 20px 0 0;
        padding: 6px 30px 6px 9px;
        width: 100%;
    }

    input:focus,
    textarea:focus {
        background-color: #fafafa;
        border-color: #aaa;
        outline-style: none;
    }

    textarea {
        resize: none;
    }

    .ui-tabs-panel {
        padding: 0;
    }
}

.sb-settings-block-footer {
    width: 100%;
    margin-top: 10px;
    padding: 0;
    text-align: right;
}

.sb-settings-block-content .alert {
    margin-top: 15px;
}

#office365-auth-tab {
    .group {
        width: 180px;
        font-size: small;
        height: 25px;
        margin-bottom: 5px;
    }
}

.modal-card-locations {
    .card-locations {
        .table {
            margin-bottom: 0;
        }
        .btn {
            margin: 0;

            &:first-child {
                margin-right: 4px;
            }
        }
    }

    .card-locations .table {
        margin-bottom: 0;
    }
}

.checkbox-default-links {
    margin: 0px !important;
    margin-top: 1em !important;
    width: 1em !important;
}
option.disabled { color: gray; }

.sb-contrast-checker-result,
.sb-alert-contrast-checker-result {

    span {
        &:first-child,
        &:nth-child(2) {
            font-weight: bold;
        }

        &.pass {
            color: green;
        }

        &.fail {
            color: red;
        }

        &.help-block {
            font-size: smaller;
            margin-top: 0px;
        }
    }
}

.subscription-item, .subscription-item .well {
    margin-bottom: 0;
}
.subscription-item .well {
    padding: 5px 10px;
}
.subscription-name {
    font-weight: bold;
}
.subscription-description {
    margin-left: 17px;
}
.unsubscribe-item label {
    font-weight: normal;
}
.unsubscribe-item input {
    width:auto;
    margin-left: 22px;
    margin-right: 0;
}
.unsubscribe-item input+label {
    font-weight:bold;
}
