@use "sass:map";
@use "../../../components/styles/variables.module" as *;
@use "./mixins";

.react-resizable-handle {
    // adding this so it sits above the page block View Page button and weather block
    z-index: 103;
}
.react-grid-item.react-grid-placeholder {
    background: transparent !important;
}
.react-resizable-handle- {
    transform: none;
    &s {
        z-index: 101;
        width: 100%;
        height: 10px;
        left: 0;
        margin-left: 0;
        cursor: s-resize;
        transform: none;
    }
    &e {
        z-index: 102;
        margin-top: 0;
        top: 0;
        height: 100%;
        width: 10px;
        cursor: e-resize;
        transform: none;
    }
}
.react-grid-item {
    transition-duration: 500ms;

    &:is(.react-draggable, .react-resizable):not(.react-draggable-dragging):not(.resizing) {
        transition: top .35s ease, left .35s ease, width .25s ease, height .25s ease, box-shadow .35s ease;
    }
}

.sb-event-list-block {
    color: $gray;

    > button {
        display: block;
        text-align: left;
    }
}

.sb-event-list-date-block {
    width: 3.2em;
    height: 3.2em;
    line-height: 1.2em;
    padding: 0.2em;
    margin-right: 18px;
    text-align: center;
    float: left;

    > span {
        display: block;
    }

    &.sb-multiday {
        padding: 0.4em 0.2em 0.2em 0.2em;
    }

    .sb-event-list-date-month {
        text-transform: uppercase;
        font-size: 0.9em;
    }

    .sb-event-list-date-day {
        font-size: 1.6em;

        &.sb-multiday {
            font-size: 0.9em;
        }
    }
}

.sb-event-list-text-block {
    /*
    height: 50px;
    */
    overflow: hidden;
    text-overflow: ellipsis;
    h4,.h4 {
        font-weight: 600;
    }
    .location {
        display: block;
    }
}

.sb-video-title-truncate {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.answerList {
    list-style: none;
    li {
        position: relative;
        margin-bottom: 1em;
        button {
            border: none;
            background: none;
            [class*="fa-"] {
                font-size: 1.5em;
            }
        }
    }
    div.input-group-addon {
        background: none;
        border: none;
    }
}

.sb-slider-pause,
.sb-slider-play {
    color: #ffffff !important;
    float: left;
    padding-left: 1em;
}

.sb-blockMessageSlider {
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
}

.sb-block {
    &:hover {
        .blockScrollbar {
            opacity: 1;
        }
    }
    .blockScrollbar {
        opacity: 0;
    }
}

.sb-message-block-slider-image {
    background: no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.sb-message-block-image {
    //width: inherit;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    &.sb-message-block-background-image {
        height: 100%;
        background: no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

.sb-facebook-album {
    .sb-message-block-image {
        position: relative;
    }
}

.sb-message-slider {
    position: unset;
    height: 100%;
    /*
     * move slider behind .sb-bottom-right-corner social icon
     * tried setting the .sb-bottom-right-corner z-index higher, but
     * then when you click the slider dots, it always goes to the
     * url link
     */
    z-index:0;

    .sb-msgHead{
        position: relative;
        padding: 1em;
        line-height: 1em;
        vertical-align: middle;
        border-top-left-radius: $block-border-radius;
        border-top-right-radius: $block-border-radius;
        span {
            font-size: 1.4em;
            line-height: 1.2em;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.sb-block-container {
    .unslider-nav {
        position: absolute;
        z-index: 11;
        left: 0;
        right: 0;
        bottom: 0;
        margin-bottom: 0;
        padding: 5px 0;
        background-color: rgba(0, 0, 0, 0.6);

        ol {
            list-style: none;
            text-align: center;

            li {
                display: inline-block;
                width: 10px;
                height: 10px;
                margin: 0 4px;
                background: transparent;
                border-radius: 5px;
                overflow: hidden;
                text-indent: -999em;
                border: 2px solid #FFF;
                cursor: pointer;
                opacity: 1;

                &.unslider-active {
                    background: #FFF;
                    cursor: default;
                }
            }
        }
    }
}

.input-group {
    margin-bottom: 1em;
}

.sb-section-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        padding-bottom: 1em;
        &:last-child {
            padding-bottom: 0;
        }
        .sb-section-item-picture {
            float: left;
            width: 4em;
            height: 4em;
            margin-right: 1em;
            border: 0;
            text-align: center;
            border-radius: $block-border-radius;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
            overflow: hidden;
            span[class*="fa-"] {
                margin-top: 0.5em;
            }
            img.sb-block-logo {
                margin-top: 0.5em;
            }
        }
    }
}


.sb-block-message {
    position: static;
    .sb-blockHead,
    .sb-blockContent {
        position: relative;

        h3 {
            font-size: 1.3em;
        }

        ul, ol {
            list-style-position: outside !important;
        }
    }
}

.sb-blockContent {
    .text-danger {
        display: block;
        text-align: center;
        margin-top: 40%;
    }

    img,
    video,
    audio {
        width: 100%;
    }

    iframe {
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0;
    }

    p {
        a {
            text-decoration: underline;
        }
    }

    p.sb-url-link {
        margin: 0;
    }
}

.sb-news-item {
    button {
        text-align: left;
        width: 100%;
    }
    span:not([class*="fa-"]) {
        color: $gray;
        font-weight: 600;
        font-size: 1.2em;
    }
    .sb-news-item-read-more {
        color: $gray;
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: 600;
        margin-top: 0.8em;
    }
}

.sb-staff-block {
    ul.staff-members {
        padding:0;
        list-style-type: none;
        li {
            padding:0;
            margin-bottom: 1em;
            cursor: pointer;

            button {
                display: block;
                width: 100%;
                text-align: left;
            }
        }
        .sb-staff-name-without-title {
            padding-left: 0;
            padding-top: 9px;
        }
        .sb-staff-name-with-title {
            padding-left: 0;
            padding-top: 0;
        }
        .sb-staff-title {
            font-size: 0.8em;
        }
    }
}

.sb-html-snippet-content {
    width: 100%;
    height: 100%;
    padding: 0 !important;

    iframe {
        padding: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.sb-twitter-card-list {
    border-bottom: 1px solid rgb(232,232,232);
    &:last-child {
        border-bottom: 0;
    }
}

.sb-facebook-post-list {
    border-bottom: 1px solid rgb(232,232,232);
    &:last-child {
        border-bottom: 0;
    }
}

.sb-facebook-embed-plugin {
    padding: 10px !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-color: inherit;

    .fb-page {
        -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);
    }
}

#sb-move-block {
    .sb-clearMoveBlockSearch {
        border: none;
        display: inline;
        background: none;
    }

    .ui-autocomplete {
        color: #333;
        border-radius: 5px;
        border-color: #ccc;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        background-color: #fff;


        .highlight {
            text-decoration: underline;
            background-color: rgb(255, 255, 219);
        }
    }
}

// Pinterest block
.sb-pinterest-link > span {
    border-radius: initial !important;
    box-shadow: initial !important;
}

// Weather block
.ws-container .ws-inner {
    z-index: initial !important;
}

.sb-files-embedded {
    .sb-file-list-entry {
        margin-bottom: 1.5em;

    }
    a:last-child .sb-file-list-entry {
        margin-bottom: 0;
    }
}

.sb-block .sb-sharedfiles-iframe-container {
    /*
    This setup allows us to absolutely position a shared files block within a relative div (the container) and allow
    scrolling inside the iframe container on mobile.
     */
    position: relative;

    .sb-sharedfiles-iframe-wrapper {
        right: 0;
        left: 0;

        &.sb-sharedfiles-google {
            /*
            XS mobile screens should have a wider margin on the left/right so that people can scroll easily without
             getting stuck on the iframe scroller when they want to scroll the page.
             */
            @include mixins.screen-xs {
                right: 1em;
                left: 1em;
            }
        }

        @include mixins.max-screen-sm {
            position: absolute;
            top: 0;
            bottom: 0;
            -webkit-overflow-scrolling: touch !important;
            overflow: auto !important;
        }

        > iframe {
            height: 100%;
            width: 100%;
        }
    }
}

.sb-flux-capacitor {
    .sb-block {
        $flux-capacitor-actions: (
                "deleted": (background-color: red, color: black),
                "parent-deleted": (background-color: red, color: black),
                "locked": (background-color: blue, color: white),
                "moved": (background-color: orange, color: black),
                "resized": (background-color: green, color: white),
                "unlocked": (background-color: blue, color: white),
        );

        @each $action, $settings in $flux-capacitor-actions {
            &.sb-flux-capacitor-#{$action} {
                border-style: dotted;
                border-width: 5px;
                border-color: map-get($settings, "background-color");
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 50000;
                    padding: 3px;
                    background-color: map-get($settings, "background-color");
                    color: map-get($settings, "color");

                    @if $action == parent-deleted {
                        content: 'Unavailable - Deleted by District';
                    } @else {
                        content: $action;
                    }
                }
            }

            $flux-capacitor-actions2: map-remove($flux-capacitor-actions, $action);
            @each $action2 in map-keys($flux-capacitor-actions2) {
                &.sb-flux-capacitor-#{$action}.sb-flux-capacitor-#{$action2}:before {
                    @if ($action == parent-deleted) or ($action2 == parent-deleted) {
                        content: 'Unavailable - Deleted by District';
                    } @else {
                        content: '#{$action} and #{$action2}';
                    }
                }

                $flux-capacitor-actions3: map-remove($flux-capacitor-actions2, $action2);
                @each $action3 in map-keys($flux-capacitor-actions3) {
                    &.sb-flux-capacitor-#{$action}.sb-flux-capacitor-#{$action2}.sb-flux-capacitor-#{$action3}:before {
                        @if ($action == parent-deleted) or ($action2 == parent-deleted) or ($action3 == parent-deleted) {
                            content: 'Unavailable - Deleted by District';
                        } @else {
                            content: '#{$action}, #{$action2}, and #{$action3}';
                        }
                    }
                }
            }
        }
    }
}
