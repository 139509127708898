@use "../../../components/styles/variables.module" as *;

//$background-color: rgb(233, 233, 233);
$background-color: rgb(248, 248, 248);

$block-background-color: rgb(255, 255, 255);

// Standard rounded corners on block elements.
$block-border-radius: 6px;

$content-top: 10em;

$column-width: 304px;
$column-width-small: 140px;

$row-height: $column-width * 0.5;
$row-height-small: $column-width-small * 0.5;

$search-row-height: $row-height + 23px;
$search-row-height-small: $row-height-small + 23px;

$block-margin-horizontal: 14px;
$block-margin-vertical: 14px;

$border-color: #ccc;

$break-point-xs: 1px;
$break-point-sm: 608px;
$break-point-md: 912px;
$break-point-lg: 1216px;

// 2.5em comes from a full block padding of 1em on each side, and .5em padding on each column
$quick-links-list: calc($column-width - 2.5em);

:export {

  background-color: $background-color;

  block-background-color: $block-background-color;

  // Standard rounded corners on block elements.
  block-border-radius: $block-border-radius;

  content-top: $content-top;

  column-width: $column-width;
  column-width-small: $column-width-small;

  row-height: $row-height;
  row-height-small: $row-height-small;

  search-row-height: $search-row-height;
  search-row-height-small: $search-row-height-small;

  block-margin-horizontal: $block-margin-horizontal;
  block-margin-vertical: $block-margin-vertical;

  border-color: $border-color;

  break-point-xs: $break-point-xs;
  break-point-sm: $break-point-sm;
  break-point-md: $break-point-md;
  break-point-lg: $break-point-lg;

  quick-links-list: $quick-links-list;
}
