/*todo: move to scss*/
#sb-organizations-map {

    position: relative;
    width: 100%;

    height: 0;
    margin-bottom: 0;
    &.active {
        border: 1px #AAA solid;
        height: 35vh;
        margin-bottom: 2rem;
    }

    .sb-block-icon {
        cursor: pointer;
        position: relative;
    }
}

.sb-map-balloon {
    position: absolute;
    top: -5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 17rem;
    padding: 1rem 2rem;
    z-index: 10;

    color: #000;
    background: #fff;
    border: 1px solid #000;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.5);
    text-align: center;
    cursor: default;
    font-size: 1rem;

    display: none;
    &.active {
        display: block;
    }

    &:after, &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #fff;
        border-width: 15px;
        margin-left: -15px;
    }
    &:before {
        border-color: rgba(0, 0, 0, 0);
        border-top-color: #000;
        border-width: 16px;
        margin-left: -16px;
    }
}



