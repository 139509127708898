@use '../../../components/styles/variables.module' as *;

$block-background-color: rgba(255, 255, 255, 0.9);


// This is for everything but iOS
.isNotIOS.isNotAndroidChrome.sb-theme-floating_blocks {
  background: var(--backgroundImage) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// This is required for iOS because mobile safari doesn't support background-attachment: fixed
// http://caniuse.com/#feat=background-attachment
.isIOS.isAndroidChrome.sb-theme-floating_blocks {
  .sb-body-bg {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%; // To compensate for mobile browser address bar space
    height: 100vh; // To compensate for mobile browser address bar space
    background: var(--backgroundImage) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #sb-content-canvas, .sb-content-canvas {
    z-index: 0;
    @media (min-width: $break-point-lg) {
      &.nav-is-visible {
        z-index: -1;
      }
    }
  }
}

.sb-theme-floating_blocks {
  .sb-top-drawer.open {
    margin-bottom: 17px;
  }

  .notification-container {
    position: sticky;
    top: $navbar-container-height + $primary-nav-height + $floating-blocks-header-margin;

    &.overlay-notification-container {
      z-index: 101;
      &:not(.notification-container-with-hero) {
        .sb-alert-overlay {
          top: 0;
        }
      }
    }

    &.banner-notification-container {
      z-index: 100;
      margin-top: $floating-blocks-header-margin;
    }
    &-with-hero {
      top: 0;
    }

    .sb-alert-overlay {
      top: 0;
    }

    .sb-alert-banner {
      margin-top: 0;
    }
  }

  .sb-sticky-nav-shadow {
    height: 0; // this is overridden by the Javascript scroll stuff
    margin-top: $floating-blocks-header-margin;
  }

  @media (min-width: $break-point-lg) {
    .sb-primary-nav {
      padding-top: $floating-blocks-header-margin;
    }
    #sb-secondary-nav {
      margin: 0 auto;
    }
  }

  .sb-primary-nav {
    background-color: transparent;


    &#primaryNav {
      @media (min-width: $break-point-lg) {
        box-shadow: none;

        .sb-navbar-primary-container {
          border-radius: 6px 6px 0 0;
          padding: 10px;
        }
      }
    }

    .sb-navbar-primary-container {
      background: var(--opaquePrimaryNavBackground);
    }
  }

  .sb-block,
  .sb-login-block,
  .sb-header-block,
  .sb-classroom-header-block,
  .sb-classroom-header-top-block,
  .sb-headerSchool-block,
  .sb-classroom-linked-floating-button,
  .sb-classroom-follow-floating-button,
  .sb-calendar-block {
    background: $block-background-color;
  }

  .sb-multigrid-block-header {
    background: rgba(255, 255, 255, 0);

    span {
      background: $block-background-color;
      border-radius: 200px;
      padding-right: 10px;
      padding-left: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--floatingBlockTextColorUseBlocks);
    }
  }

  .sb-header-block nav a.btn-default:hover {
    background-color: #FFF !important;
  }

  .sb-calendar-button.active {
    background-color: #DDD !important;
  }

  .sb-navbar-primary-element .sb-navbar-title {
    color: var(--primaryNavFontColor);
  }

  .sb-navbar-primary-element .sb-navbar-title:hover {
    color: var(--lighterPrimaryNavFontColor10);
  }

  .sb-navbar-primary-element span[class*="fa-"] {
    color: var(--primaryNavFontColor);
  }

  .sb-navbar-primary-element span[class*="fa-"]:hover {
    color: var(--lighterPrimaryNavFontColor10);
  }

  .sb-navbar-primary-element .btn {
    background-color: var(--primaryNavFontColor);
    color: var(--primaryNavFontColorTextColor);
  }

  .sb-navbar-primary-element .btn:hover {
    background-color: var(--lighterPrimaryNavFontColor10);
    color: var(--primaryNavFontColorTextColor);
  }

  .sb-secondary-nav {
    background-color: transparent;
  }

  .sb-navbar-secondary-container {
    background: var(--opaquePrimaryNavBackground);
    border-radius: 0 0 6px 6px;
    padding: 0;
  }

  .sb-organization-color-secondary-nav {
    color: var(--primaryNavFontColor);

    a, button {
      color: var(--primaryNavFontColor);
      //&:hover {
      //	color: var(--lighterPrimaryNavFontColor10;
      //}
    }

    .sb-navbar-link-text:hover,
    .sb-navbar-link-text:focus,
    .sb-navbar-link-text:active,
    .sb-navbar-link-text.active {
      border-bottom: 2px solid var(--primaryNavFontColor);
    }

    .is-mobile {
      .sb-navbar-link-text:hover,
      .sb-navbar-link-text:focus,
      .sb-navbar-link-text:active,
      .sb-navbar-link-text.active {
        border-bottom: none;
      }
    }
  }


  .sb-multi-grid .sb-block:first-child .sb-multigrid-block-header {
    border-top-left-radius: 6px;
  }

  .sb-multi-grid .sb-block:last-child .sb-multigrid-block-header {
    border-top-right-radius: 6px;
  }

  .cd-more-info {
    background-color: #FFF;
  }

  .sb-page-b-title {
    color: var(--floatingBlockTextColorUseBlocks);
  }

  .sb-blockContent h2 {
    color: var(--floatingBlockTextColorUseBlocks);
  }

  .sb-center-overlay {
    background-color: var(--blocks);
  }

  #google_translate_element {
    visibility: hidden;
  }

  @media (min-width: $break-point-lg) {
    #sb-content-canvas {
      top: inherit;

      &.sb-top-drawer-is-open {
        margin-top: 17px;
      }
    }

    // We need to specify this since the the drawer is a fixed container, where as #sb-content-canvas is absolutely positioned.
    .sb-right-drawer {
      top: 16.5em;
    }
  }

  @media only screen and (min-width: $break-point-md) {
    #google_translate_element {
      visibility: visible;
      position: absolute;
      bottom: 2em;
      left: -10px;
    }
  }

  .sb-filter-button-circles .sb-category-filter-name {
    background: rgba(255, 255, 255, 0.9);
    padding: 0.1em 0.5em;
    border-radius: 7px;
  }
}
