@use "../../../components/styles/variables.module" as *;
@use "../../../components/styles/zIndex.module";

.sb-admin-nav {
    position: fixed;
    bottom: 14px;
    left: 0;
    z-index: zIndex.$z-index-admin-buttons;
    pointer-events: auto;

    @media (max-width: $break-point-lg) {
        bottom: 64px;
    }

    .sb-admin-main-nav {
        .btn-icon {
            -webkit-border-radius:100%;
            -moz-border-radius:100%;
            border-radius:100%;
            background-color: #fff;
            border:1px solid #ccc;
            padding: 0.6em;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
            display: block;
            text-align: center;
            width: 50px;
            height: 50px;
            color: rgb(77,77,77);
            font-size: 14px;

            span[class*="fa-"]{
                font-size: 2.3em;
                width: 100%;
                //line-height:29px;
            }

            &:hover {
                color: rgb(33,33,33);
            }
        }

        ul {
            list-style: none;
            padding-left: 10px;

            li {
                margin: 7px 0;
                padding: 5px;
                position: relative;

                &.active {
                    padding-right: 80px;
                    max-width: 200px;
                    border-bottom-left-radius: 30px;
                    border-top-left-radius: 30px;
                    width: 69px;

                    span.curvetop {
                        content: '';
                        pointer-events: none;
                        position: absolute;
                        height: 40px;
                        width: 40px;
                        overflow: hidden;
                        right: -18px;
                        top: -11px;

                        &:after {
                            content: '';
                            position: absolute;
                            margin-left: -19px;
                            margin-top: -21px;
                            height: 40px;
                            width: 40px;
                            background: transparent;
                            border-radius: 25px;
                        }
                    }

                    span.curvebottom {
                        content: '';
                        pointer-events: none;
                        position: absolute;
                        height: 40px;
                        width: 40px;
                        overflow: hidden;
                        right: -17px;
                        top: 30px;

                        &:after {
                            content: '';
                            position: absolute;
                            margin-left: -18px;
                            margin-top: 22px;
                            height: 40px;
                            width: 40px;
                            background: transparent;
                            border-radius: 25px;
                        }
                    }
                }
            }
        }

    }

}

.sb-right-drawer {
    position: fixed;
    bottom: 20px;
    text-align: center;
    visibility: hidden;
    z-index: 1000;
    background: #FFF;
    border: 1px;
    border-radius: $block-border-radius;
    overflow-y: hidden;

    h1, h2, h3, h4 {
        margin: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

.sb-right-drawer-size {
    width: 16em;
    right: -16em;
}

.sb-right-drawer-header {
    position: absolute;
    top: 0;
    height: 6em;
    width: 100%;
    background-color: #838388;
    color: #fff;
    text-align: left;
    padding: 1em;

    h2 {
        font-weight: bold;
    }

    #edit-circle-close {
        position: absolute;
        top: 1em;
        right: 1em;
    }

    #edit-circle-close:hover {
        color: #BBBBBB;
    }
}


.sb-right-drawer-content {
    position: absolute;
    top: 6em;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    z-index: 1001;
    border: 1px solid #838388;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: $block-border-radius;
    border-bottom-right-radius: $block-border-radius;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            font-size: 1.5em;
            line-height: 1.5em;
        }
    }
}

#sb-admin-nav ul {
    /* mobile first */
    position: fixed;
    width: 200px;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    list-style: none;
    vertical-align: baseline;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    background: white;
    visibility: hidden;
    /* remove overflow:hidden if you want to create a drop-down menu - but then remember to fix/eliminate the list items animation */
    overflow: hidden;
    z-index: 1;
    /* Force Hardware Acceleration in WebKit */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transition: -webkit-transform 0.3s, visibility 0s 0.3s;
    -moz-transition: -moz-transform 0.3s, visibility 0s 0.3s;
    transition: transform 0.3s, visibility 0s 0.3s;

    &.is-visible {
        visibility: visible;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: -webkit-transform 0.3s, visibility 0s 0s;
        -moz-transition: -moz-transform 0.3s, visibility 0s 0s;
        transition: transform 0.3s, visibility 0s 0s;
    }

    li {
        /* Force Hardware Acceleration in WebKit */
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        a {
            display: block;
            padding: 1.6em;
            border-bottom: 1px solid #eff2f6;
            color: #000;

            &:hover {
                color: #000;
                background-color: #eee;
            }
        }

        &:last-child a {
            border-bottom: none;
        }
    }
}
