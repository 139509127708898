@use "../../../components/styles/variables.module" as *;
@use "variables.module" as v;
@use "mixins";

// This is set by Google Translator and we need to make sure it is unset or it will screw things up.
html {
  height: auto !important;

  // SBSB-1077 (https://soprisapps.atlassian.net/browse/SBSB-1077) requires that we remove the background
  // image for mobile to ensure that there is no gradient and the titles take the full width of the device
  @media(max-width: $break-point-sm) {
    --backgroundImage: none !important;
  }
}

// This is set by Google Translator and we need to make sure it is unset or it will screw things up.
body > .skiptranslate {
  display: none !important;
}
body {
  position: initial !important;
  min-height: initial !important;
  top: auto !important;

  // This allows us to communicate with JavaScript what breakpoint we're using.  This content value is overridden in
  // the media query section
  &:before {
    content: "break-point-xs";
    display: none; /* Prevent from displaying. */
  }

  &.modal-open,
  &.sb-flux-capacitor {
    #sb-content-canvas,
    .sb-content-canvas,
    .sb-top-drawer {
      z-index: auto; // required to make sure that modals are situated properly over these main content items
    }
  }

  &.modal-open {
    #sb-content-canvas,
    .sb-content-canvas,
    .sb-top-drawer {
      // When a modal is open that uses drag and drop, make sure that the main content canvas (that could contain
      // an iframe) doesn't steal pointer events from the open modal.
      pointer-events: none;
    }
  }
}

.isNotIOS.isNotAndroidChrome.sb-theme-simple_and_clean {
  background: var(--backgroundImage) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.isIOS.isAndroidChrome.sb-theme-simple_and_clean {
  .sb-body-bg {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%; // To compensate for mobile browser address bar space
    height: 100vh; // To compensate for mobile browser address bar space
    background: var(--backgroundImage) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

#main-grid-container {
  min-height: 30vh;
}

#sb-content-canvas, .sb-content-canvas {
  z-index: -1; // required to make sure that navigation panels show up on top of this canvas.
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

// Force the top of the canvas to be at the top of the screen when we're not showing the navigation bar.
.sb-navigation-hidden > #sb-content-canvas,
.sb-navigation-hidden > .sb-content-canvas {
  top: 0;
}

.modal-content > .sb-block {
  display: block;
}

.sb-block {
  border: 0;
  background: v.$block-background-color;
  border-radius: $block-border-radius;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
  overflow: hidden;
  overflow-wrap: break-word;
  position: relative;
  display: inline-block;
  z-index: 0;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  &:hover {
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.22);
  }

  .sb-block-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  // This creates an outline around blocks when focus is applied to anything inside the block.  It doesn't work
  // in all browsers, but it works in the common ones we care about.
  // https://caniuse.com/#feat=css-focus-within
  &:focus-within {
    //outline: 7px auto;
    outline-width: 7px;
    outline-style: solid;
    outline-color: red;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
  }
}

.sb-block-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.sb-block .sb-blockMedia {
  min-height: 20px;
  /*    border-top-left-radius: 6px;
      border-top-right-radius: 6px;*/
  @include mixins.min-screen-sm {
    overflow: hidden;
  }

  video {
    width: 100%;
  }
}

.sb-blockContent + .sb-blockMedia {
  margin-top: 20px;
}

.sb-block .sb-blockContent {
  /*
  border-top: 1px solid #e7e7e7;
  */
  padding: 1em;
  text-overflow: ellipsis;
  @include mixins.min-screen-sm {
    overflow: hidden;
  }
}

.sb-block .sb-blockVideoDesc {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.53) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.53) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.53) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#87000000', GradientType=0); /* IE6-9 */
  color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 12px;
  transition: opacity .8s ease;
  opacity: 0;
}

.sb-block .sb-blockVideoDesc .sb-block-videoName {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: $block-border-radius;
  margin-right: 20px;
  text-transform: uppercase;
}

.sb-block .sb-blockScroller {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  @include mixins.min-screen-sm {
    overflow: auto;
  }
  border-radius: $block-border-radius;

  &.sb-organization-color-block-bg {
    border-radius: 0;

    .sb-blockHead.sb-organization-color-block-bg {
      // an edge case on the message block where the block head and content all have the same background color
      padding-bottom: 0;
    }
  }

  &.sb-organization-color-block-font {
    .sb-blockHead.sb-organization-color-block-font {
      // an edge case on the message block where the block head and content all have the same background color
      padding-bottom: 0;
    }
  }

  > a, > a:hover {
    // This allows the focus indicator to be displayed
    display: block;
    height: 100%;
    width: 100%;
  }
}

.sb-blockScroller.sb-withBlockMeta {
  bottom: 4.4em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.sb-block .sb-blockMeta {
  position: absolute;
  height: 4.4em;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  border-top: 1px solid #e7e7e7;
  color: #777;
  padding: 0.3em;
  vertical-align: middle;
  display: table;
  width: 100%;
  border-bottom-left-radius: $block-border-radius;
  border-bottom-right-radius: $block-border-radius;
}

.sb-block .sb-blockHead {
  padding: 0.9em 1em 0.9em 1em;
  line-height: 1em;
  vertical-align: middle;
  border-top-left-radius: $block-border-radius;
  border-top-right-radius: $block-border-radius;

  span:not([class*="fa-"]) {
    font-size: 1.64rem;
    line-height: 1.98rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  a {
    position: absolute;
    right: 2em;
    top: 2em;
  }
}

.sb-block .sb-block-head-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.sb-blockMeta-content {
  vertical-align: middle;
  display: table-cell;
  float: none !important;
  text-align: left;
  padding: 4px;
}

.sb-blockMeta-content:last-child,
.sb-blockMeta-content:first-child {
  width: 1px;
  white-space: nowrap;
}

.sb-blockMeta-content:first-child {
  padding: 0;
}

.sb-blockMeta .sb-block-avatar {
  height: 30px;
  margin: 4px;
}

.sb-blockMeta .sb-block-timestamp {
  font-size: 1em;
}

.sb-block .sb-type-footer {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  padding: 6px;
  height: 56px;
  vertical-align: middle;
  text-align: center;
  border-top: 1px solid #e7e7e7;

  .sb-footer-title {
    margin-bottom: 0;
    color: $black;
  }
}

.sb-small-column-width {
  width: $column-width-small;
}

.sb-small-w1 {
  width: $column-width-small - $block-margin-horizontal;
}

.sb-small-row-height {
  height: $row-height-small;
}

/* clearfix */
.packery:after {
  content: ' ';
  display: block;
  clear: both;
}

#toolButton a {
  color: rgb(77, 77, 77);
  text-decoration: none;
}

#toolButton a:hover {
  color: rgb(33, 33, 33);
}

.draggable {
  cursor: move;
}

.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}

.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}

.ui-resizable-handle.ui-icon-gripsmall-diagonal-se {
  background-position: -48px 0;
}

.ui-resizable-handle.ui-resizable-e {
  width: 15px;
  z-index: 500 !important;
}

.ui-resizable-handle.ui-resizable-s {
  height: 15px;
  z-index: 500 !important;
}

.ui-resizable-handle.ui-resizable-se {
  z-index: 500 !important;
}

.sb-block.ui-draggable-dragging,
.sb-block.is-dragging,
.sb-block.ui-resizable-resizing,
.sb-block.is-positioning-post-drag {
  z-index: 100;
}

.sb-button {
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 1.1em;
  border-color: #ccc #c2c2c2 #b8b8b8;
  background-color: #f7f7f7;
  background: -webkit-linear-gradient(#fff, #f0f0f0);
  background: linear-gradient(#fff, #f0f0f0);
  color: #5f5f5f;
  cursor: pointer;
  font-weight: bold;
  text-shadow: 0 1px #fff;
  white-space: nowrap;
  padding: 7px 10px 7px;
  text-align: center;
  vertical-align: middle;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
}

.sb-block .sb-tool-icon {
  position: absolute;
  float: right;
  top: 6px;
  width: 37px;
  height: 37px;
  z-index: 500;
}

.sb-tool-icon {
  &.sb-edit-icon {
    right: 6px;
  }

  &.sb-move-icon {
    right: 49px;
    cursor: move;
  }

  &.sb-cannot-move-icon {
    cursor: not-allowed !important;
  }

  &.sb-lock-icon {
    right: 92px;
  }

  &.sb-repair-icon {
    right: 135px;
    color: $error-red;
  }
}

.sb-filter-button {
  display: inline-block;
  padding: .5em 1em;
  margin: .2em .5em;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 0;
  border-radius: $block-border-radius;

}

.sb-circular-profile-image {
  // must use with background-image: url();
  // and set size
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
  border-style: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);

  &.sb-small-profile-image {
    height: 3em;
    width: 3em;
  }
}

.sb-teachers-content {
  .sb-teachers-search {
    .input-group {
      width: 200px;
    }

    .form-horizontal {

      background: white;
      border-radius: 6px;
      padding: 15px;
      background: rgba(255, 255, 255, 0.9);

      margin-right: 0;
      margin-left: 0;

      label {
        color: $gray;
      }

      .form-group {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;
      }

      input {
        border-radius: 20px;
        font-size: inherit;
        line-height: inherit;
      }
    }

    margin-bottom: 0;
    margin-top: 2rem;
  }

  .sb-multigrid-block-header {
    position: absolute;
    top: -2em;
    height: 1.5em;
    left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    color: #878787;
    font-size: 1.75em;
    width: 100%;
  }
}

#my-public-information-tab .sb-teacher-modal {
  border: 1px solid lightgrey;
}

.sb-teacher-modal {
  padding: 15px;

  .row {
    margin: 0;
  }

  span[placeholder]:empty:before,
  div[placeholder]:empty:before {
    content: attr(placeholder);
    color: #AAA;
  }

  .sb-teacher-modal-top {
    color: #878787;
    margin-bottom: 35px;

    .sb-teacher-modal-info {
      h2 {
        margin-top: 0.5em;
        margin-bottom: 12px;
        color: #6B6B6B;
      }

      h4 {
        font-style: italic;
        padding-bottom: 1em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        margin-bottom: 1em;
        color: #6B6B6B;
      }

      .sb-teacher-email,
      .sb-teacher-phone {
        &:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 1.5em;
          margin-right: 1em;
          vertical-align: middle;
        }

        a {
          color: inherit;
          text-decoration: none;
        }
      }

      .sb-teacher-email:before {
        content: '\f1fa'; /* fas fa-at  */
      }

      .sb-teacher-phone:before {
        content: '\f095'; /* fas fa-phone */
      }

    }
  }

  .sb-teacher-description {
    font-size: 1.1em;
    color: #878787;
    margin-bottom: 35px;
    white-space: pre-wrap;

    p {
      margin: 0 0 0 0;
    }
  }

  .sb-teacher-classes {
    text-align: center;
    padding: 1.5em 0 0 0;


    .btn.btn-default {
      color: #777771;
      border: 2px solid #777771;
      font-weight: bold;
    }
  }

  .sb-teacher-select-img-container {
    display: table;
    font-size: 2em;
    color: #CDCDCD;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    opacity: 0;

    span[class*="fa-"] {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
  }

  .sb-teacher-select-img-container:hover {
    opacity: 1;
  }

  .sb-teacher-locations {
    padding-top: 1.5em;
    padding-bottom: 0.35em;
    margin: 0;

    select.sb-school-district,
    select.sb-department {
      width: 100%;
    }
  }
}

.sb-teacher-img-placeholder {
  position: relative;
}

.sb-teacher-img-size-big {
  height: 10em;
  width: 10em;
}

.sb-small-label {
  padding: 5px 5px 5px 5px;
}

.sb-page-b {
  position: absolute;
  top: 0;
  bottom: 3em;
  overflow: hidden;
  left: 0;
  right: 0;

  .sb-page-b-title {
    font-weight: bold;
  }

  .sb-page-b-content {
    > *:last-child {
      margin-bottom: 20px;
    }
  }

  .sb-page-b-modified {
    color: $gray;
    text-align: right;
  }
}

.sb-read-more {
  position: absolute;
  height: 3em;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1; // page block elements sometimes have z-indexes set, so let's create a stacking context on top...

  .sb-read-more-btn {
    text-transform: uppercase;
    margin-top: 0.8em;
    font-size: 0.7em;
    padding: 0.5em 1em
  }
}

.sb-expand-on-mobile {
  background: rgba(229, 229, 229, 0.85); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(rgba(229, 229, 229, 0), rgba(229, 229, 229, 1) 30%, #C5C5C5); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(rgba(229, 229, 229, 0), rgba(229, 229, 229, 1) 30%, #C5C5C5); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(rgba(229, 229, 229, 0), rgba(229, 229, 229, 1) 30%, #C5C5C5); /* For Firefox 3.6 to 15 */
  background: linear-gradient(rgba(229, 229, 229, 0), rgba(229, 229, 229, 1) 30%, #C5C5C5); /* Standard syntax */
  //box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1) inset;
  font-size: 1rem;
  position: absolute;
  height: 3em;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0.5rem 0;
  text-align: center;
  outline: none;
  border: 0;
  margin: 0;
  cursor: pointer;
  z-index: 15; // this is specifically required on message blocks to sit on top of the navigation dots

  display: none;

  &.active {
    display: block;
  }

  span {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $gray;
    font-weight: bold;
  }
}

.section-access-button {
  border: none;
  border-radius: 5px;
  background: none;
  float: right;
  text-align: center;
  font-size: 1.3em;
  padding: 5px 12px;
  margin: 4px 4px;

  div {
    font-size: 1.2rem;
    color: rgb(77, 77, 77);
  }
}

button.section-access-button:focus {
  background: #e6e6e6;
}

button.section-access-button:hover {
  background: #e6e6e6;
}

// This is a fix for iOS 9 modal windows.  See SB-743 in JIRA for explanation.
.isIOS .modal-open {
  overflow-y: auto !important;
}

.modal-footer {
  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}


#calendarManagementModal,
#notificationManagementModal {

  .modal-body {
    max-height: 600px;
    overflow-y: auto;
  }

  .submit-group {
    margin-bottom: 0;

    .btn {
      margin: 0;
    }
  }

  .calendars-list,
  .notification-list {
    .label {
      padding-bottom: 0;
    }

    a {
      font-weight: bold;
    }

    .list-header {
      margin-bottom: 20px;
      border-bottom: 1px solid #E0E0E0;
      padding: 5px 0px;
    }
  }

  .new-calendar-form,
  .new-notification-form {
    margin-bottom: 30px;
  }
}

.sb-url-block {
  cursor: pointer;
}

.sb-card {

  .sb-card-header {
    background: #f1f1f1 none repeat scroll 0 0;
    border-radius: 5px 5px 0 0;
    font-weight: bold;
    padding: 10px;
    text-align: center;
  }

  .sb-card-header-text {
    font-size: 2em;
  }

  .sb-card-content {
    padding: 15px 30px;
  }

  .category-label {
    [class*="fa-"] {
      padding-right: 3px
    }
  }

  .sb-card-contact {
    max-width: 170px;
    text-align: center;
    margin: 30px auto;

    .directions {
      padding-left: 2px;
    }
  }

  .editable {
    display: inline-block;
    border: 1px solid transparent;
    padding: 0 0 0 2px;
    cursor: pointer;
  }

  a.editable-click:hover {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 0 0 0 2px;
    background: #fff;
    /*white-space:pre !important;*/
  }
}

.modal-dialog {
  .close {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    z-index: 9999;
  }

  .packery {
    margin-top: 0;
  }

  .sb-block {
    box-shadow: none;
    margin: 0;
    width: 100%;
    min-height: $modal-min-height;
  }
}

.ui-autocomplete {
  max-height: 100px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden;
}

.sb-top-right-corner {
  position: absolute;
  top: 0;
  right: 0;
}

.sb-1-half-em-padding {
  padding: 0.5em;
}

.sb-social-icon-badge[class*="fa-"] {
  font-size: 1.5em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #FFF;
  border-radius: 50%;
}

.modal {
  pointer-events: auto !important; // need to explicitly allow pointer events in modals to counteract staff block drag & drop iframe issue fix
}

#welcome-page-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.goog-te-banner-frame.skiptranslate,
.goog-tooltip.skiptranslate,
#goog-gt-tt.skiptranslate {
  display: none !important;
}

.goog-text-highlight {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

#arrows-large-width {
  display: inline-block;
  @media (max-width: $break-point-lg) {
    display: none;
  }
}

#arrows-small-width {
  display: none;
  @media (max-width: $break-point-lg) {
    display: inline-block;
  }
}

.tagify__input:empty::before {
  opacity: 1 !important;
}

.tagify {
  --tag-hide-transition: 0s;
}
