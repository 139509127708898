/* All SCSS @media queries should go in in this file */
@use "../../../components/styles/variables.module" as *;
@use "./variables.module" as v;
@use './mixins';

// TODO: these hacks need to go away.
@media (max-width: 508px) {
    .school-modal {
        .contact {
            .left {
                display: block;
                width: 100%;
            }
            .right {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 768px) {
    .sb-header-block {
        .sb-navbar-secondary-element {
            display: block;
            padding: 4px;
        }
        nav {
            .btn-default {
                margin: 0;
                width: 100%;
            }
        }
    }
}

@media (max-width: $break-point-sm) {
    $num-columns: 1;

    .sb-navbar-primary-element .sb-navbar-logo {
        height: 50px;
    }

    // this makes blocks that should be horizontal rectangles, actually rectangles.
    // Important on mobile when there is only one column wide.
    @include mixins.block-heights($row-height);

    .sb-type-message, .sb-type-video {
        @include mixins.block-heights-aspect-ratio($row-height);
    }
}

// Extra Small Devices, Phones
@media (min-width : $break-point-xs) {
    $num-columns: 1;

    body:before {
        content: "break-point-xs"; // this is also manually set as the default breakpoint setting in InterfaceStore
    }

    body, .sb-email-styles {
        font-size: 14px;
    }

    #sb-content-canvas, .sb-content-canvas, .sb-right-drawer {
        top: 6em;
    }

    .packery, .sb-content-width {
        max-width: $column-width * $num-columns;
        margin: 0 auto;
    }

    .packery {
        position: relative;
        margin-top: -1 * ($block-margin-vertical * 0.5);
    }

    .sb-packery-container-without-map {
        width: $column-width * $num-columns;
    }

    .sb-packery-container-with-map {
        #sb-organizations-map {
            display: none;
        }
    }

    @include mixins.block-widths($column-width, $num-columns);

    @include mixins.block-heights($row-height);

    #toolButton {
        position: fixed;
        bottom: 20px;
        right: 20px;
        height: 60px;
        width: 60px;
        background-color: #FFF;
        border-radius: 60px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
        z-index: 1000;
        text-align: center;
        padding: 9px;
        color: rgb(77,77,77);
        font-size: 14px;
    }

    #toolButtonText {
        visibility: hidden;
    }

    /* Navigation menu that pops out when you click on #toolButton */
    #sb-admin-nav ul {
        right: 50px;
        bottom: 50px;
    }


    #sb-universal-modal {
        .modal-dialog {
            margin: 10px auto !important;
        }
        @include mixins.max-screen-xs {
            .modal-dialog,
            .modal-content,
            .sb-h1, .sb-h2, .sb-h3, .sb-h4, .sb-h5, .sb-h6, .sb-h7, .sb-h8, .sb-h9, .sb-h10 {
                height: 100%;
            }
        }
    }
}
@media (min-width: $break-point-xs) and (max-width: ($break-point-sm - 1)) {
    .sb-classroom-teacher {
        .sb-classroom-multiple-teachers {
            margin-right: -1.2em !important;
        }
    }
    .sb-hidden-xs {
        display: none !important;
    }

}

// Small Devices, Tablets
@media (min-width : $break-point-sm) {
    $num-columns: 2;

    body:before {
        content: "break-point-sm";
    }

    body, .sb-email-styles {
        font-size: 14px;
    }

    .packery, .sb-content-width {
        max-width: $column-width * $num-columns;
        margin: 0 auto;
    }

    .packery {
        margin-top: -1 * ($block-margin-vertical * 0.5);
    }

    .sb-packery-container-without-map {
        width: $column-width * $num-columns;
    }

    .sb-packery-container-with-map {
        float: left;
        width: $column-width * ($num-columns - 1);

        &.map-active {
            width: $column-width;
        }
    }

    @include mixins.block-widths($column-width, $num-columns);

    @include mixins.block-heights($row-height);

    #toolButton {
        position: fixed;
        bottom: 20px;
        right: 20px;
        height: 90px;
        width: 60px;
        background-color: #FFF;
        border-radius: 60px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
        z-index: 1000;
        text-align: center;
        padding: 6px;
        color: rgb(77,77,77);
    }

    #toolButtonText {
        visibility: visible;
        font-size: small;
        margin: 4px 0 0 0;
    }

    /* Navigation menu that pops out when you click on #toolButton */
    #sb-admin-nav ul {
        right: 50px;
        bottom: 62px;
    }

    .sb-block.sb-w2 .sb-block-list.sb-sub,
    .sb-block.sb-w3 .sb-block-list.sb-sub,
    .sb-block.sb-w4 .sb-block-list.sb-sub {
        li {
            width: v.$quick-links-list;
        }
    }

    .sb-add-block-form,
    .sb-edit-block-form {
        margin-right: $column-width + 30;
    }
    .sb-card-sponsor .sb-card-contact{
        margin:30px 20px;
        max-width: inherit;
        text-align: left;
    }
    .sponsor-list-logo {
        display:inline-block;
    }
    .sponsor-select .btn-group .multiselect{
        width:320px !important;
    }
    .sb-teacher-card .sb-teacher-img-size-small {
        height: 7em;
        width: 7em;
    }
}
@media (min-width: $break-point-sm) and (max-width: ($break-point-md - 1)) {
    .sb-hidden-sm {
        display: none !important;
    }
}
@media (min-width: $break-point-xs) and (max-width: ($break-point-md - 1)) {
    .sb-navbar-secondary-container {
        text-align: left !important;
    }
}

// Medium Devices
@media (min-width : $break-point-md) {
    $num-columns: 3;

    body:before {
        content: "break-point-md";
    }

    .packery, .sb-content-width {
        max-width: $column-width * $num-columns;
        margin: 0 auto;
    }

    .packery {
        margin-top: -1 * ($block-margin-vertical * 0.5);
    }

    .sb-packery-container-without-map {
        width: $column-width * $num-columns;
    }

    .sb-packery-container-with-map {
        float: left;
        width: $column-width * ($num-columns - 1);

        &.map-active {
            width: $column-width;
        }
    }

    @include mixins.block-widths($column-width, $num-columns);

    .sb-grid-with-microsite {
        @include mixins.block-widths($column-width, $num-columns - 1);
    }

    .sb-block.sb-w3 .sb-block-list.sb-sub,
    .sb-block.sb-w4 .sb-block-list.sb-sub {
        li {
            width: v.$quick-links-list;
        }
    }

    .sb-card .sb-card-contact{
        margin:20px 0px;
        max-width: inherit;
        text-align: left;
    }
}

@media (min-width: $break-point-md) and (max-width: ($break-point-lg - 1)) {
    .sb-hidden-md {
        display: none !important;
    }
}

/* Large Devices, Wide Screens */
@media (min-width : $break-point-lg) {
    $num-columns: 4;

    body:before {
        content: "break-point-lg";
    }

    #sb-content-canvas, .sb-content-canvas, .sb-right-drawer {
        top: 10em;
    }

    .packery, .sb-content-width {
        max-width: $column-width * $num-columns;
        margin: 0 auto;
    }
    .sb-content-width-plus-margin {
        margin: 0 auto;
    }
    .sb-title-org-packery .sb-content-width-plus-margin {
        max-width: ($column-width * $num-columns) - $block-margin-horizontal;
    }
    .sb-title-org-terraced .sb-content-width-plus-margin {
        max-width: calc(100% - #{$terrace-outer-margin * 2});
    }

    .packery {
        margin-top: -1 * ($block-margin-vertical * 0.5);
    }

    .sb-packery-container-without-map {
        width: $column-width * $num-columns;
    }

    .sb-packery-container-with-map {
        float: left;
        width: $column-width * ($num-columns - 1);

        &.map-active {
            width: $column-width;
        }
    }

    @include mixins.block-widths($column-width, $num-columns);

    .sb-grid-with-microsite {
        @include mixins.block-widths($column-width, $num-columns - 1);
    }

    .sb-hidden-lg {
        display: none !important;
    }

    .sb-block.sb-w4 .sb-block-list.sb-sub {
        li {
            width: v.$quick-links-list;
        }
    }
}

.fade-background {
    // do not change without evaluating $terrace-title-max-width variable
    background: linear-gradient(90deg, rgba(255,255,255,.9) 0%, rgba(255,255,255,.7) 70%, rgba(255,255,255,0) 100%) !important;
}
