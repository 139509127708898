@use "variables.module";

.sb-teacher-card {
  table-layout: fixed;
  width: 100%;

  .sb-teacher-card-image-cell {
    text-align: center;
    vertical-align : middle;
    width: 40%;
  }

  .sb-teacher-img-size-small {
    display: inline-block;
    height: 4em;
    width: 4em;
  }

  .sb-teacher-card-info-cell {
    text-align: left;
    vertical-align : top;
    width: 60%;
    overflow-y: hidden;
    div.sb-h1 {
      padding: 1em 1em 1em 0.5em;
    }
  }

  .sb-teacher-card-name {
    font-weight:bold;
    word-wrap: break-word;
  }

  .sb-teacher-card-title {
    font-size: 0.9em;
    font-style: italic;
    overflow-y: hidden;
    height: 100%;
  }
}
