@use 'variables.module';

.sb-organization-color-primary-nav {
    background-color: var(--primaryNavBackground);
    color: var(--primaryNavBackgroundTextColor);
    a.sb-organization-font-color {
        color: inherit;
    }

    a.sb-home-link {
        &:hover {
            color: #ccc !important;
        }
    }
}

.sb-organization-color-secondary-nav {
    background-color: var(--secondaryNavBackground);
    color: var(--secondaryNavBackgroundTextColor);
    a {
        color: var(--secondaryNavBackgroundTextColor);
    }

    $darkerBgColor: var(--darkerBgColor);
    $navbarSeparator: var(--darkerBgColor5);
    border-top: 2px solid $navbarSeparator;

    .sb-navbar-link-text:hover,
    .sb-navbar-link-text:focus,
    .sb-navbar-link-text:active,
    .sb-navbar-link-text.active {
        border-bottom: 2px solid var(--secondaryNavBackgroundTextColor);
    }
}

.sb-organization-color-block-font {
    color: var(--blockBgTextColorUseBlocks);

    a, a:hover {
        color: var(--blockBgTextColorUseBlocks);
    }
}

.sb-organization-color-block-bg {
    background-color: var(--blocks);
    color: var(--blocksTextColor);

    a, a:hover {
        color: var(--blocksTextColor);
    }
}

.sb-organization-color-element-bg,
a.sb-organization-color-element-bg {
    background-color: var(--elements);
    color: var(--elementsTextColorNoBlocks);

    &:hover,
    &:focus {
        $darkerBgColor: var(--darkerElements10);
        background-color: $darkerBgColor;
        color: var(--darkerElements10TextColor);
    }
}

.sb-organization-color-element-outline,
a.sb-organization-color-element-outline {
    border-color: var(--elements);
    border-width: 2px;
    color: var(--elements);

    &:hover,
    &:focus {
        $darkerBgColor: var(--darkerElements10);
        border-color: $darkerBgColor;
        color: $darkerBgColor;
    }
}

.sb-organization-color-element-font,
a.sb-organization-color-element-font {
    color: var(--elements);

    &:hover,
    &:focus {
        $darkerBgColor: var(--darkerElements10);
        color: $darkerBgColor;
    }
}

.sb-admin-nav {
    .sb-admin-main-nav {
        ul {
            li.active {
                background: var(--secondaryNavBackground);

                span.curvetop:after, span.curvebottom:after {
                    border: solid 8px var(--secondaryNavBackground);
                }
            }
        }
    }
}
