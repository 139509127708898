// Icons box
.rfipicons {
	// Pager
	&__pager {
		display: flex;
		flex-flow: row nowrap;
		height: 24px;
		line-height: 24px;
		align-items: center;
		margin-bottom: 8px;
	}

	// num
	&__num {
		width: 100px;
		margin-right: auto;
	}
	// current page
	&__cp {
		width: 32px;
		margin-right: 8px;
		height: 24px;
		line-height: 24px;
		text-align: right;
	}
	// separator
	&__sp {
		margin-right: 8px;
	}
	// total page
	&__tp {
		margin-right: 8px;
	}

	// arrows
	&__arrow {
		margin-left: auto;
		width: 56px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
		height: 24px;
		user-select: none;
	}
	// left and right
	&__right {
		margin-left: auto;
	}
	&__left,
	&__right {
		cursor: pointer;
		width: 24px;
		height: 24px;
		position: relative;
		transition: background-color 250ms, border 250ms;
		outline: 0 none;
		border-radius: 2px;
		font-size: 18px;
	}

	// Label
	&__label {
		height: 22px;
		width: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 18px;
			width: 18px;
		}
	}

	// Icon Selector
	&__selector {
		display: flex;
		flex: 1 1 20%;
		flex-flow: row wrap;
		align-content: center;
		justify-content: flex-start;
	}

	// Individual icon box
	&__ibox {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		transition: background-color 250ms, border 250ms;
		border-radius: 2px;
		outline: 0 none;
		font-size: 20px;
		img,
		svg {
			max-height: 24px;
			width: auto;
		}
		> * {
			transform: scale(1);
			transition: transform 250ms;
			transform-origin: center;
		}
		&:hover {
			> * {
				transform: scale(1.8);
			}
		}
		// Error
		&--error {
			text-transform: lowercase;
			font-style: italic;
		}
	}

	// Invidual icons
	&__icon {
		width: 20%;
		height: 64px;
		padding: 1px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		// Error
		&--error {
			display: block;
			padding: 16px;
			text-align: center;
			font-size: 24px;
			width: 100%;
			line-height: 1;
		}
	}
}
