@use "variables.module";

#sb-notification-drawer {
  .sb-notification {
    &.open {
      .sb-alert-banner {
        visibility: visible;
      }
    }
  }

  &.cd-top-submenu-open {
    z-index: -1 !important; // we want this to be behind the open navigation submenu

    .sb-notification {
      &.open {
        .sb-alert-banner {
          visibility: hidden;
        }
      }
    }
  }
}

.notification-list {
  .input-group {
    margin-bottom: 0;
  }
}

.sb-alert-banner {
  visibility: hidden;
  margin-bottom: 0;

  .alert-content {
    display: block;
    height: auto;

    p {
      text-align: center;
      margin: 0;
    }
  }
}

.notification-container {
  top: 0;
  &.overlay-notification-container:not(.notification-container-with-hero) {
    .sb-alert-overlay {
      height: 100vh;
    }
  }
}

.sb-alert-overlay {
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  position: fixed;
  overflow-y: auto;
  z-index: 1001; // sit above schoolfeed header, above primary nav container, below/at level with secondary nav

  .sb-alert-overlay-box {
    height: auto;
    padding: 10px;
    max-width: 800px;
    position: relative;
    top: 40%;
    border-radius: 10px;
    margin: 0 auto;
    transform: translateY(-50%);
    box-shadow: 0 0 10px #666;

    .sb-box-message {
      text-align: center;
    }

    .sb-box-action {
      text-align: center;

      button {
        font-size: 20px;
        padding: 6px 6px;
        border-radius: 6px;
        display: inline-block;
      }
    }
  }
}

.sb-alert-banner, .sb-alert-overlay {
  font-size: 16px;

  h3 {
    font-size: 1.25em;
  }

  ul, ol {
    padding: 0;
    list-style-position: inside !important;
  }

  a {
    color: inherit !important;
  }
}
