// Dropdown
.rfipdropdown {
	width: 352px;
	position: absolute;
	left: 0;
	margin-top: -1px;
	z-index: 100000001;
	border-radius: 0 1px 4px 4px;

	// Selector box
	&__selector {
		overflow: hidden;
		padding: 16px;
	}

	// For animation
	&.fipappear-enter-active,
	&.fipappear-exit-active {
		.rfipdropdown__selector {
			transition: max-height 300ms ease-out, padding 300ms ease-out;
			padding: 16px;
		}
	}
}
