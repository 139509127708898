// This file is used in packages/cleversite and packages/schoolblocks!

// any global styles can be applied here
@use "../../components/pages/PageBlockPage/styles/scopedContentBuilderContent";
@use "../components/blocks/SchoolBlocks/WeatherBlock/WeatherBlock";

#mobile-only {
  @media(max-width: 1220px) {
    display: block;
  }
}

#desktop-only {
  @media(max-width: 1220px) {
    display: none;
  }
}

// https://soprisapps.atlassian.net/browse/SBSB-1353
// https://github.com/xobotyi/react-scrollbars-custom/issues/164#issuecomment-808489412
// Need this style applied globally to prevent "flashing" when a component implements
// react-scrollbars-custom and has a height right on the border of requiring a scrollbar to be rendered.
.ScrollbarsCustom-Scroller {
  margin-right: 0px !important;
  overflow: auto !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
