@use "../../../components/styles/variables.module" as *;

.sb-top-drawer {
    width: 100%;
    text-align: center;
    visibility: hidden;
    position: relative;

    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.sb-top-drawer-container {
    width: 100%;
}

.sb-header-block {
    display: block;
    position: relative;
    height: auto;
    border: 0;
    background: #FFF;
    border-radius: $block-border-radius;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    margin: 0 7px;
    padding: 20px 10px;
    text-align: center;
    overflow: hidden;
    nav {
        a.btn-default {
            padding: 10px;
            color: $gray;
            font-size: 1em;
        }
        a.btn-default:hover {
            color: #212121;
            background-color: inherit;
        }
    }
}

.sb-header-block-title {
    color: #606060;
    text-decoration: none;
    margin: 15px auto;
  a {
      color: inherit;
  }
  a:hover {
    color: inherit;
  }
}

.sb-header-block-close {
    position: absolute;
    top: 10px;
    right: 10px;
    a {
        color: #454545;
    }
    a:hover {
        color: #2B2B2B;
    }
}

.sb-header-block-avatar {
    display: inline-block;
    max-height: 37px;
    border-radius: 30px;
    margin-right: .5rem;
}

.sb-headerSchool-block {
    display: block;
    position: relative;
    border: 0;
    background: #FFF;
    border-radius: $block-border-radius;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    margin: 0 7px;
    text-align: center;
    overflow: hidden;
    padding: 1.5em 0;
}
