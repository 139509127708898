@use "variables.module";

.sb-alert-banner {
  position: relative;
  z-index: 1;
  color: var(--fontColor);
  background-color: var(--backgroundColor);
}
.sb-alert-overlay {
  color: var(--fontColor);
  background-color: var(--backgroundColor90Opacity);

  .sb-alert-overlay-box {
    background-color: var(--backgroundColor);
  }
}
.sb-box-action {
  button {
    border: 1px solid var(--fontColor);
    background-color: var(--backgroundColor);

    &:hover { // the background/font colors are inverted from the above colors.
      border: 1px solid var(--backgroundColor);
      color: var(--backgroundColor) !important;
      background-color: var(--fontColor) !important;
    }
  }
}
