/* Search category colors */
@use '../../../components/styles/variables.module' as *;
@use './mixins';

.sb-category-filter-button:not(.active):not(:hover) {
    background-color: #CCC !important;
    outline:0 none !important;
    color: #000 !important;
    box-shadow: none;

    &.sb-category-filter-button-transparent {
        background-color: transparent !important;
        color: #BCBEC0 !important;
    }
}

.sb-filter-button-circles{
    display:inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;

    margin:0.7em 0.5em 0;

    width: 5.5em;
    @include mixins.min-screen-sm {
        width: 8rem;
    }
    .sb-category-filter-name{
        font-size: 1.2rem;
         color:#777;
        display: block; //makes the background color look nicer on floating blocks theme.
     }
     .sb-filter-button-circle:not(.active):not(:hover):not(:focus) {
         .icon-svgtag-background-fill {
             fill: #ccc !important
         }
     }
     .sb-filter-button-circle {
         box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
         background: #aaa;
         display: inline-block;
         border-radius: 100%;
         border-width: 0;
         margin:.8em .5em;
         padding: 0;

         width:5rem;
         height:5rem;

         .icon-fa{
             font-size:3em;
         }
         .icon-img{
             width:3em;
             height:3em;
         }
         .icon-svg, .icon-svgtag {
             height: inherit;
             width: inherit;
         }
     }
 }

.sb-block-icon-container {
    position: absolute;
    top: 1.4em;
    right: 0.8em;
}

.sb-block-icon {
    text-align: center;
    display: inline-block;
    position: relative;
    z-index: 20;
    width: 2.35em;
    height: 2.35em;
    background: #fff;
    border-radius:100%;

    .icon-fa{
        font-size:1.1em;
    }
    .icon-img{
        width:1em;
        height:1em;
    }
    .icon-svg, .icon-svgtag {
        width: 2.35em;
        height: 2.35em;
        margin-top: -0.15em;
    }
}

.sb-background-color-content_item-news.sb-filter-button,
.sb-background-color-content_item-event.sb-filter-button,
.sb-background-color-content_item-file.sb-filter-button,
.sb-background-color-district.sb-filter-button,
.sb-background-color-school.sb-filter-button,
.sb-background-color-classroom.sb-filter-button,
.sb-background-color-section.sb-filter-button,
.sb-background-color-group.sb-filter-button,
.sb-background-color-department.sb-filter-button,
.sb-background-color-google-drive.sb-filter-button,
.sb-background-color-staff.sb-filter-button,
.sb-background-color-page.sb-filter-button,
.sb-organization-color-element-bg.sb-filter-button:not(.active):not(:hover) {
    background-color: #CCC;
	color: #000;
}
$color-district: #cc2a36;
$color-district-light: #FFF;
.sb-background-color-content_item-event,
.sb-background-color-district {
    background-color: $color-district;
    color: #FFF;

    &.sb-filter-button:hover,
    &.sb-filter-button:active,
    &.sb-filter-button.active {
        background-color: $color-district;
        color: #FFF;
    }
}

$color-school: #EFB03F;
$color-school-light: #FAE0B8;
.sb-background-color-content_item-news,
.sb-background-color-school {
    background-color: $color-school;
    color: #FFF;

    &.sb-filter-button:hover,
    &.sb-filter-button:active,
    &.sb-filter-button.active {
        background-color: $color-school;
        color: #FFF;
    }
}

$color-classroom: #8BBF4E;
$color-classroom-light: #C8E0A8;
.sb-background-color-classroom {
    background-color: $color-classroom;
    color: #FFF;

    &.sb-filter-button:hover,
    &.sb-filter-button:active,
    &.sb-filter-button.active {
        background-color: $color-classroom;
        color: #FFF;
    }
}

$color-section: #00A4E4;
$color-section-light: #00A4E4;
.sb-background-color-content_item-file,
.sb-background-color-section {
    background-color: $color-section;
    color: #FFF;

    &.sb-filter-button:hover,
    &.sb-filter-button:active,
    &.sb-filter-button.active {
        background-color: $color-section;
        color: #FFF;
    }
}

$color-group: #9679AA;
$color-group-light: #D4C9DD;
.sb-background-color-group {
    background-color: $color-group;
    color: #FFF;

    &.sb-filter-button:hover,
    &.sb-filter-button:active,
    &.sb-filter-button.active {
        background-color: $color-group;
        color: #FFF;
    }
}

$color-department: #ff7000;
$color-department-light: #ff7000;
.sb-background-color-department {
    background-color: $color-department;
    color: #000;

    &.sb-filter-button:hover,
    &.sb-filter-button:active,
    &.sb-filter-button.active {
        background-color: $color-department;
        color: #000;
    }
}

$color-google-drive: #2A70E8;
$color-google-drive-light: #4385F6;
.sb-background-color-google-drive {
    background-color: $color-google-drive;
    color: #FFF;

    &.sb-filter-button:hover,
    &.sb-filter-button:active,
    &.sb-filter-button.active {
        background-color: $color-google-drive;
        color: #FFF;
    }
}

$color-staff: #1e5d0b;
$color-staff-light: #0d8508;
.sb-background-color-staff {
    background-color: $color-staff;
    color: #FFF;

    &.sb-filter-button:hover,
    &.sb-filter-button:active,
    &.sb-filter-button.active {
        background-color: $color-staff;
        color: #FFF;
    }
}

$color-page: #009900;
$color-page-light: #0d8508;
.sb-background-color-page {
    background-color: $color-page;
    color: #FFF;

    &.sb-filter-button:hover,
    &.sb-filter-button:active,
    &.sb-filter-button.active {
        background-color: $color-page;
        color: #FFF;
    }
}

/* School type colors */
.sb-background-color-pre-school.sb-filter-button,
.sb-background-color-elementary-school.sb-filter-button,
.sb-background-color-middle-school.sb-filter-button,
.sb-background-color-high-school.sb-filter-button,
.sb-background-color-alternative-school.sb-filter-button,
.sb-background-color-other-school.sb-filter-button{
    background-color: #CCC;
	outline:0 none;
	color: #000;
}
.sb-background-color-pre-school,
.sb-background-color-pre-school.sb-filter-button:hover,
.sb-background-color-pre-school.sb-filter-button:active,
.sb-background-color-pre-school.sb-filter-button.active {
    background-color: #eb6841; color: #FFF;
}

.sb-background-color-elementary-school,
.sb-background-color-elementary-school.sb-filter-button:hover,
.sb-background-color-elementary-school.sb-filter-button:active,
.sb-background-color-elementary-school.sb-filter-button.active {
    background-color: #edc951; color: #000;
}

.sb-background-color-middle-school,
.sb-background-color-middle-school.sb-filter-button:hover,
.sb-background-color-middle-school.sb-filter-button:active,
.sb-background-color-middle-school.sb-filter-button.active {
    background-color: #cc2a36; color: #FFF;
}

.sb-background-color-high-school,
.sb-background-color-high-school.sb-filter-button:hover,
.sb-background-color-high-school.sb-filter-button:active,
.sb-background-color-high-school.sb-filter-button.active {
    background-color: #4f372d; color: #FFF;
}

.sb-background-color-alternative-school,
.sb-background-color-alternative-school.sb-filter-button:hover,
.sb-background-color-alternative-school.sb-filter-button:active,
.sb-background-color-alternative-school.sb-filter-button.active {
    background-color: #00a0b0; color: #FFF;
}

.sb-background-color-other-school,
.sb-background-color-other-school.sb-filter-button:hover,
.sb-background-color-other-school.sb-filter-button:active,
.sb-background-color-other-school.sb-filter-button.active {
    background-color: #F96F2F; color: #FFF;
}

/* Internal blocks */
.sb-background-color-page { background-color: #009900; }

.sb-background-color-poll { background-color: #FF6600; }

/* 3rd Party Blocks */
.sb-background-color-twitter { background-color: #55ACEE; }

.sb-background-color-edmodo { background-color: #376BB0; }

$color-facebook: #3b5999;
.sb-background-color-facebook { background-color: $color-facebook; }

.sb-background-color-pinterest { background-color: #cc2127; }

.sb-background-color-schoology { background-color: #211F20; }

.sb-background-color-instagram { background-color: #3f729b; }

.sb-background-color-vimeo { background-color: #1BB7EA; }

.sb-background-color-youtube { background-color: #cd201f; }

.sb-background-color-schooltube { background-color: #000000; }

.sb-background-color-google { background-color: #4285f4; }

.sb-background-color-google-red { background-color: #dd4b39; }

.sb-background-color-urllink { background-color: #4285f4;}

.sb-background-color-message-white { background-color: #ffffff;}
.sb-background-color-message-dark { background-color: #830D33;}
.sb-background-color-message-light { background-color: #1BB7EA;}

.sb-background-color-news { background-color: #1BB7EA;}

//$color-office365: #ea3e23;
$color-office365: #cc2e15;
.sb-background-color-office365 { background-color: $color-office365; }
