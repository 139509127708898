$flux-background-color: #444;
$flux-timeline-color: #dfdfdf;
$flux-timeline-filled-color: #7b9d6f;

.sb-flux-capacitor-pane {
    z-index: 1050;
    position: fixed;
    margin-top: 0.5em;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s;

    #sb-flux-capacitor-cancel {
        color: $flux-timeline-color;
        text-shadow: none;
        opacity: 1;
        font-size: 2em;
        margin: 0 0.2em 0 0.5em;
    }

    .cd-horizontal-timeline {
        position: relative;
        height: 10em;
        opacity: 1;
        background-color: $flux-background-color !important;
        color: #fff;
        padding: 0 1.5em;
    }

    .timeline-header {
        position: absolute;
        top: 1em;
        left: 0;
        width: 100%;
    }

    .timeline-context {
        position: absolute;
        bottom: 1em;
        left: 0;
        width: 100%;
    }

    .tooltip {
        margin-top: 1.3em;
    }

    .sb-flux-capacitor-actions {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
    }
}

.cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;

    &::before {
        /* never visible - this is used in jQuery to check the current MQ */
        content: 'mobile';
        display: none;
    }

    &.loaded {
        /* show the timeline after events position has been set (using JavaScript) */
        opacity: 1;
    }

    .timeline {
        position: absolute;
        height: 5em;
        width: 100%;
        top: 3em;
        left: 0;
        padding: 0 1.5em;
    }

    .events-wrapper {
        position: relative;
        height: 100%;
        margin: 0 3em;
        overflow: hidden;
    }

    .events {
        /* this is the grey line/timeline */
        position: absolute;
        z-index: 1;
        left: 0;
        top: 2em;
        height: 2px;
        /* width will be set using JavaScript */
        background: $flux-timeline-color;
        -webkit-transition: -webkit-transform 0.4s;
        -moz-transition: -moz-transform 0.4s;
        transition: transform 0.4s;

        a {
            position: absolute;
            bottom: 0;
            z-index: 2;
            text-align: center;
            font-size: 1.3rem;
            padding-bottom: 15px;
            color: transparent;
            /* fix bug on Safari - text flickering while timeline translates */
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);

            &::after {
                /* this is used to create the event spot */
                content: '';
                position: absolute;
                left: 50%;
                right: auto;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                bottom: -9px;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                border: 5px solid $flux-timeline-color;
                background-color: $flux-background-color;
            }

            &.selected {
                cursor: not-allowed;

                &::after {
                    background-color: $flux-timeline-filled-color;
                    border-color: $flux-timeline-filled-color;
                }
            }

            &.older-event::after {
                border-color: $flux-timeline-filled-color;
            }
        }

        ol {
            list-style-type: none;
            margin: 0;
        }
    }

    .filling-line {
        /* this is used to create the green line filling the timeline */
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: $flux-timeline-filled-color;
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -ms-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: $flux-timeline-filled-color;
    border-color: $flux-timeline-filled-color;
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
}

@media only screen and (min-width: 1100px) {
    .cd-horizontal-timeline {
        margin: 6em auto;
    }
    .cd-horizontal-timeline::before {
        /* never visible - this is used in jQuery to check the current MQ */
        content: 'desktop';
    }
}

.events-content ol,
ul.cd-timeline-navigation {
    list-style-type: none;
    margin: 0;
}

.cd-timeline-navigation a {
    /* these are the left/right arrows to navigate the timeline */
    position: absolute;
    z-index: 1;
    top: 2em;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 2.5em;
    width: 2.5em;
    border-radius: 50%;
    border: 2px solid $flux-timeline-color;
    color: $flux-timeline-color;
    padding: 0.5em 0;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: border-color 0.3s, color 0.3s;
    -moz-transition: border-color 0.3s, color 0.3s;
    transition: border-color 0.3s, color 0.3s;

    &.prev {
        left: 1.5em;
    }
    &.next {
        right: 1.5em;
    }
    span[class*="fa-"] {
        font-size: 1.2em;
    }

    &.inactive {
        cursor: not-allowed;
    }
}

.no-touch .cd-timeline-navigation a {
    &:hover {
        border-color: $flux-timeline-filled-color;
        color: $flux-timeline-filled-color;
    }

    &.inactive:hover {
        border-color: $flux-timeline-color;
        color: $flux-timeline-color;
    }
}


