// Category item
.rfipcategory {
	width: 100%;
	margin: 0 0 8px;
	position: relative;
	select {
		width: 100%;
		display: block;
		height: 32px;
		line-height: 32px;
		border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none; /* remove default arrow */
		transition: border 250ms, box-shadow 250ms;
		background-color: transparent !important;
	}
	i {
		position: absolute;
		right: 2px;
		top: 0;
		font-size: 16px;
		line-height: 32px;
		z-index: -1;
	}
}
