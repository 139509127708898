.goog-inline-block {
    position: relative;
    display: -moz-inline-box;
    display: inline-block
}

.drive-sprite-aux-add-file {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) -87px -126px;
    background-size: 307px 472px;
    width: 220px;
    height: 220px
}

.drive-sprite-aux-computer {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) 0 -126px;
    background-size: 307px 472px;
    width: 30px;
    height: 25px
}

.drive-sprite-aux-document {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) -101px -347px;
    background-size: 307px 472px;
    width: 100px;
    height: 125px
}

.drive-sprite-aux-download-file {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) 0 -152px;
    background-size: 307px 472px;
    width: 58px;
    height: 75px
}

.drive-sprite-aux-drive-doc {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) 0 0;
    background-size: 307px 472px;
    width: 100px;
    height: 125px
}

.drive-sprite-aux-drive-doc-gray {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) -101px 0;
    background-size: 307px 472px;
    width: 100px;
    height: 125px
}

.drive-sprite-aux-image {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) -202px -347px;
    background-size: 307px 472px;
    width: 100px;
    height: 125px
}

.drive-sprite-aux-image-gray {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) 0 -347px;
    background-size: 307px 472px;
    width: 100px;
    height: 125px
}

.drive-sprite-aux-offline-check {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) -59px -126px;
    background-size: 307px 472px;
    width: 26px;
    height: 25px
}

.drive-sprite-aux-trash-warning {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) 0 -228px;
    background-size: 307px 472px;
    width: 86px;
    height: 99px
}

.drive-sprite-aux-video {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux-0ffe4313f8b7667a4bf7df304b0fdf75.png) -202px 0;
    background-size: 307px 472px;
    width: 100px;
    height: 125px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-aux-add-file {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) -86px 0;
        background-size: 306px 470px;
        width: 220px;
        height: 220px
    }

    .drive-sprite-aux-computer {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) 0 0;
        background-size: 306px 470px;
        width: 30px;
        height: 25px
    }

    .drive-sprite-aux-document {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) -100px -220px;
        background-size: 306px 470px;
        width: 100px;
        height: 125px
    }

    .drive-sprite-aux-download-file {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) 0 -25px;
        background-size: 306px 470px;
        width: 58px;
        height: 75px
    }

    .drive-sprite-aux-drive-doc {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) -200px -345px;
        background-size: 306px 470px;
        width: 100px;
        height: 125px
    }

    .drive-sprite-aux-drive-doc-gray {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) 0 -345px;
        background-size: 306px 470px;
        width: 100px;
        height: 125px
    }

    .drive-sprite-aux-image {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) 0 -220px;
        background-size: 306px 470px;
        width: 100px;
        height: 125px
    }

    .drive-sprite-aux-image-gray {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) -200px -220px;
        background-size: 306px 470px;
        width: 100px;
        height: 125px
    }

    .drive-sprite-aux-offline-check {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) -30px 0;
        background-size: 306px 470px;
        width: 26px;
        height: 25px
    }

    .drive-sprite-aux-trash-warning {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) 0 -100px;
        background-size: 306px 470px;
        width: 86px;
        height: 99px
    }

    .drive-sprite-aux-video {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/aux_hdpi-5a5f12f605e9124c074c633d8a4a7757.png) -100px -345px;
        background-size: 306px 470px;
        width: 100px;
        height: 125px
    }
}

.drive-sprite-core-activitybubble {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -159px;
    background-size: 220px 241px;
    width: 107px;
    height: 82px
}

.drive-sprite-core-activity-error {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -148px -21px;
    background-size: 220px 241px;
    width: 27px;
    height: 24px
}

.drive-sprite-core-anyone-with-link {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -170px;
    background-size: 220px 241px;
    width: 13px;
    height: 17px
}

.drive-sprite-core-anyone-with-link-at-domain {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -136px -61px;
    background-size: 220px 241px;
    width: 13px;
    height: 17px
}

.drive-sprite-core-anyone-with-link-at-domain-blue {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -193px -159px;
    background-size: 220px 241px;
    width: 13px;
    height: 17px
}

.drive-sprite-core-anyone-with-link-at-domain-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -75px;
    background-size: 220px 241px;
    width: 13px;
    height: 17px
}

.drive-sprite-core-anyone-with-link-blue {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px -22px;
    background-size: 220px 241px;
    width: 13px;
    height: 17px
}

.drive-sprite-core-anyone-with-link-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -176px -38px;
    background-size: 220px 241px;
    width: 13px;
    height: 17px
}

.drive-sprite-core-arrow-down {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -107px -113px;
    background-size: 220px 241px;
    width: 10px;
    height: 5px
}

.drive-sprite-core-arrow-left {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -215px -144px;
    background-size: 220px 241px;
    width: 5px;
    height: 10px
}

.drive-sprite-core-arrow-right {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -193px -216px;
    background-size: 220px 241px;
    width: 5px;
    height: 10px
}

.drive-sprite-core-arrow-up {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -68px;
    background-size: 220px 241px;
    width: 9px;
    height: 6px
}

.drive-sprite-core-back {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -193px -194px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-broken-link {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px -92px;
    background-size: 220px 241px;
    width: 19px;
    height: 14px
}

.drive-sprite-core-broken-link-blue {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -11px;
    background-size: 220px 241px;
    width: 19px;
    height: 14px
}

.drive-sprite-core-check-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -188px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-chevron-left {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -75px -22px;
    background-size: 220px 241px;
    width: 7px;
    height: 12px
}

.drive-sprite-core-chevron-right {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -105px 0;
    background-size: 220px 241px;
    width: 7px;
    height: 12px
}

.drive-sprite-core-close {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -164px -125px;
    background-size: 220px 241px;
    width: 13px;
    height: 12px
}

.drive-sprite-core-colorcheck {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -99px -38px;
    background-size: 220px 241px;
    width: 11px;
    height: 9px
}

.drive-sprite-core-comment {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px 0;
    background-size: 220px 241px;
    width: 18px;
    height: 14px
}

.drive-sprite-core-connect-more {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -107px -65px;
    background-size: 220px 241px;
    width: 13px;
    height: 13px
}

.drive-sprite-core-create {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -38px;
    background-size: 220px 241px;
    width: 13px;
    height: 13px
}

.drive-sprite-core-create-menu-new-folder {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -64px -113px;
    background-size: 220px 241px;
    width: 20px;
    height: 16px
}

.drive-sprite-core-domain {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px -130px;
    background-size: 220px 241px;
    width: 13px;
    height: 11px
}

.drive-sprite-core-domain-blue {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -66px -70px;
    background-size: 220px 241px;
    width: 13px;
    height: 11px
}

.drive-sprite-core-domain-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -93px;
    background-size: 220px 241px;
    width: 13px;
    height: 11px
}

.drive-sprite-core-download-large {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -176px 0;
    background-size: 220px 241px;
    width: 16px;
    height: 17px
}

.drive-sprite-core-download-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -26px;
    background-size: 220px 241px;
    width: 11px;
    height: 11px
}

.drive-sprite-core-drive {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -58px -130px;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-drive-folder {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -176px -21px;
    background-size: 220px 241px;
    width: 20px;
    height: 16px
}

.drive-sprite-core-drive-logo-color {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -46px;
    background-size: 220px 241px;
    width: 24px;
    height: 21px
}

.drive-sprite-core-edit {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -25px -46px;
    background-size: 220px 241px;
    width: 13px;
    height: 13px
}

.drive-sprite-core-edit-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px 0;
    background-size: 220px 241px;
    width: 13px;
    height: 13px
}

.drive-sprite-core-group {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -58px -39px;
    background-size: 220px 241px;
    width: 16px;
    height: 8px
}

.drive-sprite-core-group-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -15px;
    background-size: 220px 241px;
    width: 16px;
    height: 8px
}

.drive-sprite-core-info {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -131px -43px;
    background-size: 220px 241px;
    width: 16px;
    height: 16px
}

.drive-sprite-core-link {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -118px -144px;
    background-size: 220px 241px;
    width: 18px;
    height: 7px
}

.drive-sprite-core-link-blue {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px -107px;
    background-size: 220px 241px;
    width: 18px;
    height: 7px
}

.drive-sprite-core-locked {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -113px -21px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-mole-min-max {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -52px;
    background-size: 220px 241px;
    width: 13px;
    height: 3px
}

.drive-sprite-core-more {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -107px -61px;
    background-size: 220px 241px;
    width: 15px;
    height: 3px
}

.drive-sprite-core-new-file {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px -48px;
    background-size: 220px 241px;
    width: 16px;
    height: 16px
}

.drive-sprite-core-new-folder {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 0;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-next {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -107px -119px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-next-left {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -210px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-no-activity {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -150px -61px;
    background-size: 220px 241px;
    width: 70px;
    height: 63px
}

.drive-sprite-core-offline-status {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -104px;
    background-size: 220px 241px;
    width: 16px;
    height: 16px
}

.drive-sprite-core-organize {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -61px -48px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-page {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -107px -79px;
    background-size: 220px 241px;
    width: 28px;
    height: 33px
}

.drive-sprite-core-page-left {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -173px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-page-right {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -61px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-person {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -66px -192px;
    background-size: 220px 241px;
    width: 12px;
    height: 13px
}

.drive-sprite-core-person-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -105px -144px;
    background-size: 220px 241px;
    width: 12px;
    height: 13px
}

.drive-sprite-core-popout {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -135px -21px;
    background-size: 220px 241px;
    width: 12px;
    height: 12px
}

.drive-sprite-core-preview {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -58px 0;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-public {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -14px -93px;
    background-size: 220px 241px;
    width: 16px;
    height: 16px
}

.drive-sprite-core-public-blue {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -153px;
    background-size: 220px 241px;
    width: 16px;
    height: 16px
}

.drive-sprite-core-public-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -58px -22px;
    background-size: 220px 241px;
    width: 16px;
    height: 16px
}

.drive-sprite-core-search {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px -70px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-search-large {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -113px -43px;
    background-size: 220px 241px;
    width: 17px;
    height: 17px
}

.drive-sprite-core-settings {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -68px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-share {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px -192px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-share-blue {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -144px;
    background-size: 220px 241px;
    width: 19px;
    height: 14px
}

.drive-sprite-core-share-disabled {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -131px;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-share-white {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -110px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-sort {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -150px -125px;
    background-size: 220px 241px;
    width: 13px;
    height: 18px
}

.drive-sprite-core-toolbar-delete {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -134px 0;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-toolbar-grid {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -152px;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-toolbar-info {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -113px 0;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-toolbar-list {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -132px;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-toolbar-more {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -155px 0;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-toolbar-organize {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -193px 0;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-toolbar-settings {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -197px -21px;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-toolbar-share {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -64px -92px;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-toolbar-sort-alpha {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -195px;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-toolbar-sort-bars {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -83px;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-trash {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -216px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-upload {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -193px -177px;
    background-size: 220px 241px;
    width: 19px;
    height: 16px
}

.drive-sprite-core-upload-file {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px -121px;
    background-size: 220px 241px;
    width: 15px;
    height: 18px
}

.drive-sprite-core-upload-folder {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px -214px;
    background-size: 220px 241px;
    width: 20px;
    height: 16px
}

.drive-sprite-core-video-play-grid {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -44px -151px;
    background-size: 220px 241px;
    width: 40px;
    height: 40px
}

.drive-sprite-core-video-play-list {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -110px;
    background-size: 220px 241px;
    width: 20px;
    height: 20px
}

.drive-sprite-core-view-options-grid {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) 0 -22px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-view-options-list {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -22px -24px;
    background-size: 220px 241px;
    width: 21px;
    height: 21px
}

.drive-sprite-core-warning {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core-6f50fc396aa8eda032e3d9ffd20af7ae.png) -85px 0;
    background-size: 220px 241px;
    width: 11px;
    height: 10px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-core-activitybubble {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 -45px;
        background-size: 203px 243px;
        width: 107px;
        height: 82px
    }

    .drive-sprite-core-activity-error {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -52px 0;
        background-size: 203px 243px;
        width: 27px;
        height: 24px
    }

    .drive-sprite-core-anyone-with-link {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -79px -14px;
        background-size: 203px 243px;
        width: 13px;
        height: 17px
    }

    .drive-sprite-core-anyone-with-link-at-domain {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -47px -127px;
        background-size: 203px 243px;
        width: 13px;
        height: 17px
    }

    .drive-sprite-core-anyone-with-link-at-domain-blue {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -102px -12px;
        background-size: 203px 243px;
        width: 13px;
        height: 17px
    }

    .drive-sprite-core-anyone-with-link-at-domain-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -104px -183px;
        background-size: 203px 243px;
        width: 13px;
        height: 17px
    }

    .drive-sprite-core-anyone-with-link-blue {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -131px -101px;
        background-size: 203px 243px;
        width: 13px;
        height: 17px
    }

    .drive-sprite-core-anyone-with-link-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -34px -127px;
        background-size: 203px 243px;
        width: 13px;
        height: 17px
    }

    .drive-sprite-core-arrow-down {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -191px -140px;
        background-size: 203px 243px;
        width: 10px;
        height: 5px
    }

    .drive-sprite-core-arrow-left {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -159px -210px;
        background-size: 203px 243px;
        width: 5px;
        height: 10px
    }

    .drive-sprite-core-arrow-right {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -97px 0;
        background-size: 203px 243px;
        width: 5px;
        height: 10px
    }

    .drive-sprite-core-arrow-up {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -155px 0;
        background-size: 203px 243px;
        width: 9px;
        height: 6px
    }

    .drive-sprite-core-back {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -21px -186px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-broken-link {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -155px -7px;
        background-size: 203px 243px;
        width: 19px;
        height: 14px
    }

    .drive-sprite-core-broken-link-blue {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -131px -87px;
        background-size: 203px 243px;
        width: 19px;
        height: 14px
    }

    .drive-sprite-core-check-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -83px -127px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-chevron-left {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 -210px;
        background-size: 203px 243px;
        width: 7px;
        height: 12px
    }

    .drive-sprite-core-chevron-right {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -63px -210px;
        background-size: 203px 243px;
        width: 7px;
        height: 12px
    }

    .drive-sprite-core-close {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -102px 0;
        background-size: 203px 243px;
        width: 13px;
        height: 12px
    }

    .drive-sprite-core-colorcheck-hdpi {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -131px -118px;
        background-size: 203px 243px;
        width: 11px;
        height: 9px
    }

    .drive-sprite-core-comment {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -79px 0;
        background-size: 203px 243px;
        width: 18px;
        height: 14px
    }

    .drive-sprite-core-connect-more {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -190px -45px;
        background-size: 203px 243px;
        width: 13px;
        height: 13px
    }

    .drive-sprite-core-create {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -102px -29px;
        background-size: 203px 243px;
        width: 13px;
        height: 13px
    }

    .drive-sprite-core-create-menu-new-folder {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -134px -21px;
        background-size: 203px 243px;
        width: 20px;
        height: 16px
    }

    .drive-sprite-core-domain {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -154px -21px;
        background-size: 203px 243px;
        width: 13px;
        height: 11px
    }

    .drive-sprite-core-domain-blue {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -115px -19px;
        background-size: 203px 243px;
        width: 13px;
        height: 11px
    }

    .drive-sprite-core-domain-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 -222px;
        background-size: 203px 243px;
        width: 13px;
        height: 11px
    }

    .drive-sprite-core-download-large {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -149px -45px;
        background-size: 203px 243px;
        width: 16px;
        height: 17px
    }

    .drive-sprite-core-download-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -40px -21px;
        background-size: 203px 243px;
        width: 12px;
        height: 12px
    }

    .drive-sprite-core-drive {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -165px -65px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-drive-folder {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -20px -21px;
        background-size: 203px 243px;
        width: 20px;
        height: 16px
    }

    .drive-sprite-core-drive-logo-color {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -107px -103px;
        background-size: 203px 243px;
        width: 24px;
        height: 21px
    }

    .drive-sprite-core-edit {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -50px -210px;
        background-size: 203px 243px;
        width: 13px;
        height: 13px
    }

    .drive-sprite-core-edit-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -21px -127px;
        background-size: 203px 243px;
        width: 13px;
        height: 13px
    }

    .drive-sprite-core-group {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -83px -197px;
        background-size: 203px 243px;
        width: 16px;
        height: 8px
    }

    .drive-sprite-core-group-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -115px -30px;
        background-size: 203px 243px;
        width: 16px;
        height: 8px
    }

    .drive-sprite-core-info {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -107px -87px;
        background-size: 203px 243px;
        width: 16px;
        height: 16px
    }

    .drive-sprite-core-link {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -164px 0;
        background-size: 203px 243px;
        width: 18px;
        height: 7px
    }

    .drive-sprite-core-link-blue {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -79px -31px;
        background-size: 203px 243px;
        width: 18px;
        height: 7px
    }

    .drive-sprite-core-locked {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -20px 0;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-mole-min-max {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -115px 0;
        background-size: 203px 243px;
        width: 13px;
        height: 3px
    }

    .drive-sprite-core-more {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 -42px;
        background-size: 203px 243px;
        width: 15px;
        height: 3px
    }

    .drive-sprite-core-new-file {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 -20px;
        background-size: 203px 243px;
        width: 16px;
        height: 16px
    }

    .drive-sprite-core-new-folder {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -21px -144px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-next {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -107px -66px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-next-left {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -62px -164px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-no-activity {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -132px -147px;
        background-size: 203px 243px;
        width: 70px;
        height: 63px
    }

    .drive-sprite-core-offline-status {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -104px -147px;
        background-size: 203px 243px;
        width: 16px;
        height: 16px
    }

    .drive-sprite-core-organize {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -98px -210px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-page {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -70px -210px;
        background-size: 203px 243px;
        width: 28px;
        height: 33px
    }

    .drive-sprite-core-page-left {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -107px -45px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-page-right {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -167px -21px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-person {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -188px -21px;
        background-size: 203px 243px;
        width: 12px;
        height: 13px
    }

    .drive-sprite-core-person-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -191px -127px;
        background-size: 203px 243px;
        width: 12px;
        height: 13px
    }

    .drive-sprite-core-popout {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -83px -148px;
        background-size: 203px 243px;
        width: 12px;
        height: 12px
    }

    .drive-sprite-core-preview {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -62px -143px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-public {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -34px -210px;
        background-size: 203px 243px;
        width: 16px;
        height: 16px
    }

    .drive-sprite-core-public-blue {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 -148px;
        background-size: 203px 243px;
        width: 16px;
        height: 16px
    }

    .drive-sprite-core-public-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -83px -181px;
        background-size: 203px 243px;
        width: 16px;
        height: 16px
    }

    .drive-sprite-core-search {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -172px -220px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-search-large {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 -184px;
        background-size: 203px 243px;
        width: 17px;
        height: 17px
    }

    .drive-sprite-core-settings {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -83px -160px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-share {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -21px -165px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-share-blue {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -172px -127px;
        background-size: 203px 243px;
        width: 19px;
        height: 14px
    }

    .drive-sprite-core-share-disabled {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -152px -127px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-share-white {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 -127px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-sort {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -159px -220px;
        background-size: 203px 243px;
        width: 13px;
        height: 18px
    }

    .drive-sprite-core-toolbar-delete {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 -164px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-toolbar-grid {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -165px -45px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-toolbar-info {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -139px -210px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-toolbar-list {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -42px -165px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-toolbar-more {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -119px -210px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-toolbar-organize {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) 0 0;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-toolbar-settings {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -104px -127px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-toolbar-share {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -132px -127px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-toolbar-sort-alpha {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -42px -186px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-toolbar-sort-bars {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -104px -163px;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-trash {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -128px -45px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-upload {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -115px -3px;
        background-size: 203px 243px;
        width: 19px;
        height: 16px
    }

    .drive-sprite-core-upload-file {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -117px -183px;
        background-size: 203px 243px;
        width: 15px;
        height: 18px
    }

    .drive-sprite-core-upload-folder {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -62px -127px;
        background-size: 203px 243px;
        width: 20px;
        height: 16px
    }

    .drive-sprite-core-video-play-grid {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -150px -87px;
        background-size: 203px 243px;
        width: 40px;
        height: 40px
    }

    .drive-sprite-core-video-play-list {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -182px 0;
        background-size: 203px 243px;
        width: 20px;
        height: 20px
    }

    .drive-sprite-core-view-options-grid {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -134px 0;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-view-options-list {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -13px -210px;
        background-size: 203px 243px;
        width: 21px;
        height: 21px
    }

    .drive-sprite-core-warning {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/core_hdpi-52e1445cb0ec423061215856bc1158d3.png) -104px -200px;
        background-size: 203px 243px;
        width: 11px;
        height: 10px
    }
}

.drive-sprite-download-promo-mac-macgraphic {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/download_promo_mac-66c73966b59c948f4dd9beb4578e05ca.png) 0 0;
    background-size: 237px 148px;
    width: 237px;
    height: 148px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-download-promo-mac-macgraphic {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/download_promo_mac_hdpi-65a36f5abf64cec93d7ea681f8a831b2.png) 0 0;
        background-size: 237px 148px;
        width: 237px;
        height: 148px
    }
}

.drive-sprite-download-promo-win-pcgraphic {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/download_promo_win-c52ab81524f020a4fe41f67aa63dc5b6.png) 0 0;
    background-size: 237px 148px;
    width: 237px;
    height: 148px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-download-promo-win-pcgraphic {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/download_promo_win_hdpi-1d6bee6576c6345b54a357647dd22a4d.png) 0 0;
        background-size: 237px 148px;
        width: 237px;
        height: 148px
    }
}

.drive-sprite-empty-drive-banner {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_drive-dc7c0b7f06100a0d1a43503b03081889.png) 0 0;
    background-size: 358px 334px;
    width: 358px;
    height: 233px
}

.drive-sprite-empty-drive-devices {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_drive-dc7c0b7f06100a0d1a43503b03081889.png) 0 -234px;
    background-size: 358px 334px;
    width: 130px;
    height: 83px
}

.drive-sprite-empty-drive-share {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_drive-dc7c0b7f06100a0d1a43503b03081889.png) -131px -234px;
    background-size: 358px 334px;
    width: 165px;
    height: 100px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-empty-drive-banner {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_drive_hdpi-37bd356dff104e65ccf1faec031267f3.png) 0 0;
        background-size: 358px 333px;
        width: 358px;
        height: 233px
    }

    .drive-sprite-empty-drive-devices {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_drive_hdpi-37bd356dff104e65ccf1faec031267f3.png) 0 -233px;
        background-size: 358px 333px;
        width: 130px;
        height: 83px
    }

    .drive-sprite-empty-drive-share {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_drive_hdpi-37bd356dff104e65ccf1faec031267f3.png) -130px -233px;
        background-size: 358px 333px;
        width: 165px;
        height: 100px
    }
}

.drive-sprite-empty-view-activity {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view-d7e30af37699ed1e635be15a6d264069.png) -81px -234px;
    background-size: 271px 321px;
    width: 100px;
    height: 87px
}

.drive-sprite-empty-view-folder {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view-d7e30af37699ed1e635be15a6d264069.png) -81px 0;
    background-size: 271px 321px;
    width: 190px;
    height: 233px
}

.drive-sprite-empty-view-offline {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view-d7e30af37699ed1e635be15a6d264069.png) 0 -88px;
    background-size: 271px 321px;
    width: 68px;
    height: 66px
}

.drive-sprite-empty-view-opened {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view-d7e30af37699ed1e635be15a6d264069.png) 0 -155px;
    background-size: 271px 321px;
    width: 74px;
    height: 74px
}

.drive-sprite-empty-view-shared-with-me {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view-d7e30af37699ed1e635be15a6d264069.png) 0 -230px;
    background-size: 271px 321px;
    width: 80px;
    height: 77px
}

.drive-sprite-empty-view-star {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view-d7e30af37699ed1e635be15a6d264069.png) -182px -234px;
    background-size: 271px 321px;
    width: 83px;
    height: 78px
}

.drive-sprite-empty-view-trash {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view-d7e30af37699ed1e635be15a6d264069.png) 0 0;
    background-size: 271px 321px;
    width: 76px;
    height: 87px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-empty-view-activity {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view_hdpi-26e36ad7e662012a3bc10c19812bcec4.png) -83px -233px;
        background-size: 266px 320px;
        width: 100px;
        height: 87px
    }

    .drive-sprite-empty-view-folder {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view_hdpi-26e36ad7e662012a3bc10c19812bcec4.png) -76px 0;
        background-size: 266px 320px;
        width: 190px;
        height: 233px
    }

    .drive-sprite-empty-view-offline {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view_hdpi-26e36ad7e662012a3bc10c19812bcec4.png) 0 -87px;
        background-size: 266px 320px;
        width: 68px;
        height: 66px
    }

    .drive-sprite-empty-view-opened {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view_hdpi-26e36ad7e662012a3bc10c19812bcec4.png) 0 -153px;
        background-size: 266px 320px;
        width: 74px;
        height: 74px
    }

    .drive-sprite-empty-view-shared-with-me {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view_hdpi-26e36ad7e662012a3bc10c19812bcec4.png) -183px -233px;
        background-size: 266px 320px;
        width: 80px;
        height: 77px
    }

    .drive-sprite-empty-view-star {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view_hdpi-26e36ad7e662012a3bc10c19812bcec4.png) 0 -233px;
        background-size: 266px 320px;
        width: 83px;
        height: 78px
    }

    .drive-sprite-empty-view-trash {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/empty_view_hdpi-26e36ad7e662012a3bc10c19812bcec4.png) 0 0;
        background-size: 266px 320px;
        width: 76px;
        height: 87px
    }
}

.drive-sprite-folder-grid-icon.icon-color-1 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 0;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-10 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1118px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-11 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -344px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-12 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1892px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-13 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -946px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-14 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -774px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-15 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -602px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-16 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1032px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-17 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -86px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-18 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1720px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-19 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1548px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-2 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1462px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-20 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -430px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-21 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -516px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-22 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1806px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-23 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -258px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-24 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -172px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-3 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1634px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-4 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -860px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-5 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1978px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-6 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1290px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-7 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1376px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-8 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -1204px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-icon.icon-color-9 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid-31c7c563e7d78647941ff4c326af96b5.png) 0 -688px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-folder-grid-icon.icon-color-1 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1615px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-10 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1275px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-11 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1445px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-12 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1530px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-13 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -765px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-14 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1955px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-15 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1360px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-16 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1870px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-17 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -935px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-18 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -850px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-19 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1190px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-2 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -170px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-20 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 0;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-21 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1020px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-22 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -510px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-23 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -680px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-24 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -425px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-3 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -340px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-4 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1700px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-5 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -255px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-6 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -595px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-7 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1105px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-8 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -1785px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-icon.icon-color-9 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_hdpi-6501498c68eee304d7934cd5766122e8.png) 0 -85px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }
}

.drive-sprite-folder-grid-shared-icon.icon-color-1 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 0;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-10 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1118px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-11 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -344px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-12 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1892px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-13 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -946px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-14 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -774px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-15 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -602px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-16 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1032px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-17 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -86px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-18 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1720px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-19 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1548px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-2 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1462px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-20 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -430px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-21 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -516px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-22 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1806px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-23 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -258px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-24 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -172px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-3 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1634px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-4 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -860px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-5 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1978px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-6 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1290px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-7 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1376px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-8 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -1204px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

.drive-sprite-folder-grid-shared-icon.icon-color-9 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared-dd6e1d6bc653b51bf5ed81010a52160d.png) 0 -688px;
    background-size: 110px 2063px;
    width: 110px;
    height: 85px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-folder-grid-shared-icon.icon-color-1 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1615px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-10 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1275px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-11 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1445px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-12 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1530px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-13 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -765px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-14 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1955px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-15 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1360px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-16 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1870px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-17 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -935px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-18 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -850px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-19 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1190px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-2 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -170px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-20 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 0;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-21 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1020px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-22 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -510px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-23 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -680px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-24 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -425px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-3 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -340px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-4 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1700px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-5 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -255px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-6 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -595px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-7 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1105px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-8 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -1785px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }

    .drive-sprite-folder-grid-shared-icon.icon-color-9 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_grid_shared_hdpi-c7d7554827ae997b12276ce1428e4c67.png) 0 -85px;
        background-size: 110px 2040px;
        width: 110px;
        height: 85px
    }
}

.drive-sprite-folder-list-icon.icon-color-1 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 0;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-10 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -182px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-11 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -56px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-12 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -308px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-13 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -154px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-14 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -126px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-15 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -98px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-16 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -168px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-17 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -14px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-18 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -280px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-19 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -252px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-2 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -238px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-20 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -70px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-21 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -84px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-22 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -294px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-23 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -42px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-24 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -28px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-3 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -266px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-4 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -140px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-5 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -322px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-6 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -210px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-7 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -224px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-8 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -196px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-icon.icon-color-9 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list-b50435e37fca82b700ce413b4d657092.png) 0 -112px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-folder-list-icon.icon-color-1 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -266px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-10 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -210px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-11 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -238px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-12 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -252px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-13 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -126px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-14 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -322px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-15 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -224px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-16 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -308px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-17 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -154px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-18 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -140px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-19 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -196px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-2 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -28px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-20 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 0;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-21 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -168px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-22 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -84px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-23 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -112px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-24 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -70px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-3 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -56px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-4 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -280px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-5 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -42px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-6 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -98px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-7 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -182px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-8 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -294px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-icon.icon-color-9 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_hdpi-2e088b6180f6be744f20382872723d58.png) 0 -14px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }
}

.drive-sprite-folder-list-shared-icon.icon-color-1 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 0;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-10 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -182px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-11 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -56px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-12 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -308px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-13 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -154px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-14 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -126px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-15 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -98px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-16 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -168px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-17 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -14px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-18 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -280px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-19 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -252px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-2 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -238px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-20 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -70px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-21 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -84px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-22 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -294px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-23 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -42px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-24 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -28px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-3 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -266px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-4 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -140px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-5 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -322px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-6 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -210px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-7 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -224px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-8 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -196px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

.drive-sprite-folder-list-shared-icon.icon-color-9 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared-42c0c113afece2f86061c72f1124556f.png) 0 -112px;
    background-size: 16px 335px;
    width: 16px;
    height: 13px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-folder-list-shared-icon.icon-color-1 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -266px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-10 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -210px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-11 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -238px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-12 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -252px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-13 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -126px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-14 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -322px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-15 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -224px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-16 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -308px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-17 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -154px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-18 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -140px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-19 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -196px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-2 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -28px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-20 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 0;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-21 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -168px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-22 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -84px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-23 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -112px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-24 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -70px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-3 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -56px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-4 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -280px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-5 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -42px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-6 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -98px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-7 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -182px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-8 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -294px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }

    .drive-sprite-folder-list-shared-icon.icon-color-9 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/folder_list_shared_hdpi-c4218ea2db4d9e97314b21c90eb72e65.png) 0 -14px;
        background-size: 16px 336px;
        width: 16px;
        height: 14px
    }
}

.drive-sprite-view-promo-activity {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/view_promo-13fbb971072f73bc35e2f0a312bbd146.png) -232px -26px;
    background-size: 258px 131px;
    width: 26px;
    height: 25px
}

.drive-sprite-view-promo-my-drive {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/view_promo-13fbb971072f73bc35e2f0a312bbd146.png) 0 0;
    background-size: 258px 131px;
    width: 231px;
    height: 131px
}

.drive-sprite-view-promo-opened {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/view_promo-13fbb971072f73bc35e2f0a312bbd146.png) -232px -52px;
    background-size: 258px 131px;
    width: 26px;
    height: 25px
}

.drive-sprite-view-promo-shared-with-me {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/view_promo-13fbb971072f73bc35e2f0a312bbd146.png) -232px 0;
    background-size: 258px 131px;
    width: 26px;
    height: 25px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-view-promo-activity {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/view_promo_hdpi-31b4dcb8300982db02cfa19fc73d5a17.png) -231px -25px;
        background-size: 257px 131px;
        width: 26px;
        height: 25px
    }

    .drive-sprite-view-promo-my-drive {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/view_promo_hdpi-31b4dcb8300982db02cfa19fc73d5a17.png) 0 0;
        background-size: 257px 131px;
        width: 231px;
        height: 131px
    }

    .drive-sprite-view-promo-opened {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/view_promo_hdpi-31b4dcb8300982db02cfa19fc73d5a17.png) -231px -50px;
        background-size: 257px 131px;
        width: 26px;
        height: 25px
    }

    .drive-sprite-view-promo-shared-with-me {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/view_promo_hdpi-31b4dcb8300982db02cfa19fc73d5a17.png) -231px 0;
        background-size: 257px 131px;
        width: 26px;
        height: 25px
    }
}

.drive-sprite-welcome-welcome-image-devices {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome-1cfca9d0e29a5ed86df85455cbb7776c.png) 0 0;
    background-size: 247px 117px;
    width: 191px;
    height: 117px
}

.drive-sprite-welcome-welcome-promo-arrow-1 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome-1cfca9d0e29a5ed86df85455cbb7776c.png) -192px -68px;
    background-size: 247px 117px;
    width: 55px;
    height: 37px
}

.drive-sprite-welcome-welcome-promo-arrow-2 {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome-1cfca9d0e29a5ed86df85455cbb7776c.png) -192px -19px;
    background-size: 247px 117px;
    width: 50px;
    height: 48px
}

.drive-sprite-welcome-welcome-promo-close-x {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome-1cfca9d0e29a5ed86df85455cbb7776c.png) -211px 0;
    background-size: 247px 117px;
    width: 18px;
    height: 18px
}

.drive-sprite-welcome-welcome-promo-close-x-hover {
    background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome-1cfca9d0e29a5ed86df85455cbb7776c.png) -192px 0;
    background-size: 247px 117px;
    width: 18px;
    height: 18px
}

@media screen and (-webkit-device-pixel-ratio: 2) {
    .drive-sprite-welcome-welcome-image-devices {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome_hdpi-649f72b64f58c111d42878cbdc8324dc.png) -55px 0;
        background-size: 246px 118px;
        width: 191px;
        height: 118px
    }

    .drive-sprite-welcome-welcome-promo-arrow-1 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome_hdpi-649f72b64f58c111d42878cbdc8324dc.png) 0 -48px;
        background-size: 246px 118px;
        width: 55px;
        height: 37px
    }

    .drive-sprite-welcome-welcome-promo-arrow-2 {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome_hdpi-649f72b64f58c111d42878cbdc8324dc.png) 0 0;
        background-size: 246px 118px;
        width: 50px;
        height: 48px
    }

    .drive-sprite-welcome-welcome-promo-close-x {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome_hdpi-649f72b64f58c111d42878cbdc8324dc.png) -18px -85px;
        background-size: 246px 118px;
        width: 18px;
        height: 18px
    }

    .drive-sprite-welcome-welcome-promo-close-x-hover {
        background: no-repeat url(//ssl.gstatic.com/docs/doclist/images/welcome_hdpi-649f72b64f58c111d42878cbdc8324dc.png) 0 -85px;
        background-size: 246px 118px;
        width: 18px;
        height: 18px
    }
}

