/// Gives a card depth effect.
/// @param {Number} $depth - depth level (between 1 and 5)
/// @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
/// @requires {function} top-shadow
/// @requires {function} bottom-shadow
@mixin card($depth) {
	@if $depth < 1 {
		box-shadow: none;
	} @else if $depth > 5 {
		@warn 'Invalid $depth `#{$depth}` for mixin `card`.';
	} @else {
		box-shadow: bottom-shadow($depth), top-shadow($depth);
	}
}
/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
	$primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
	$blur: nth(1.5 3 10 14 19, $depth) * 4px;
	$color: rgba(black, nth(0.12 0.16 0.19 0.25 0.3, $depth));

	@return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
	$primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
	$blur: nth(1 3 3 5 6, $depth) * 4px;
	$color: rgba(black, nth(0.24 0.23 0.23 0.22 0.22, $depth));

	@return 0 $primary-offset $blur $color;
}

/// Input type element
@mixin input($elem-border-color, $elem-border-focus-color) {
	border: 0 none;
	border-bottom: 1px solid $elem-border-color;
	transition: box-shadow 250ms, border 250ms;
	&:focus,
	&:active {
		border-bottom-color: $elem-border-focus-color;
		box-shadow: 0 1px 0 0 $elem-border-focus-color;
		outline: 0 none;
	}
}
