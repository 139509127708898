@use "../../../components/styles/variables.module" as *;

.sb-search-groups.ui-autocomplete-loading + .sb-loading-icon {
    display: inline-block;
}

.sb-group-permissions-profile-icon-container {
    display: table;

    .sb-group-permissions-profile-icon {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        position: relative;
        font-size: 1.5em;
    }
}

#permissionsModal {
    .form-group {
        .btn-block {
            margin: 0;
        }

        .form-control,
        .btn-block,
        .remove-icon span[class*="fa-"] {
            height: 40px;
        }

        .sb-small-profile-image {
            margin: 0 auto;
        }

        .role-select {
            font-weight: bold;
            color: #777;
        }

        .remove-icon {
            color: $gray;
            span {
                cursor: pointer;
                line-height: 40px;
            }
        }

        h3 {
            font-weight: bold;
            color: #777;
        }

        p.form-control-static {
            color: #888;
            padding: 0;
        }

        .last-login {
            margin-top: -0.5em;
        }

        .control-margin {
            & > * {
                margin-top: 0.5em;
                margin-bottom: 0.5em;
            }
        }
    }
}
